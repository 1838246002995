// Home.js
import React from 'react';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
const Contactus = () => {
  return (
    <>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Outlet</h3>
    </div>

    <div className="container-fluid aboutus">
      <div className="contact-panel-1">
        <h4 className="contact-h4">Contact us</h4>
        <h3 className="contact-h3">Let’s talk About Your Project</h3>
        <p className="contact-para">Start working with Us that can provide everything you need to generate awareness, drive traffic, connect. <br/>We guarantee that you’ll be able to have any issue resolved within 24 hours.</p>
      </div>
        <div className="container">
          <div className="chennai">
            <div className="row contact-panel-2">
              <h3 className="head-office"><span className='cookscape'>Get a Quick Enquiry</span></h3>
              <div className="col-md-6 pt-5">
                  <div className="contact-link mt-5">
                              <p className="forservice"> For Service : <a href="#" className="maillink">service@cookscape.com</a></p>
                  </div>

                  <div className="contact-link">
                                <p className="forservice"> For Enquiry : <a href="#" className="maillink">service@cookscape.com</a></p>
                  </div>

                  <div className="contact-link">
                                <p className="forservice"> For Carrers : <a href="#" className="maillink">service@cookscape.com</a></p>
                  </div>
              </div> 
              <div className="col-md-6">
              <div className="contact-form-container">
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
              </div>  

            </div> 
            <div class="row contact-panel-2">
              <h3 class="head-office"><span className='cookscape'>HEAD OFFICE - NANDANAM</span></h3>
              <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">557/338, Anna Salai, Fanepet, Nandanam, <br/>Chennai, Tamil Nadu 600035,</a></li>
                            </ul>
                </div>
                </div>
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe
                        class="embed-responsive-item" target="_blank" rel="noopener noreferrer"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62204.59126240308!2d80.199425!3d12.985474!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52675cc1b592db%3A0xf8f2f9dd6546a7ed!2sCookscape%20Factory!5e0!3m2!1sen!2sus!4v1727506424348!5m2!1sen!2sus"
                        ></iframe>
                        </div>
                </div>
            </div>

            <div class="row contact-panel-2">
            <h3 class="head-office"><span className='cookscape'>PORUR</span></h3>
              
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.049129796885!2d80.1636061!3d13.0325434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5260e01e9e3571%3A0x605857cd6f7d7f54!2sCookscape%20Interior%20-%20Porur!5e0!3m2!1sen!2sus!4v1733491331069!5m2!1sen!2sus"></iframe>
                        </div>
                </div>

                <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">64 First Floor, PH Road, Porur, Near Saravana Stores Porur Chennai, 600116.</a></li>
                            </ul>
                </div>
                </div>
            </div>

            <div class="row contact-panel-2">
            <h3 class="head-office"><span>OMR</span></h3>
              <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">38/103 1st Floor, Thoraipakkam ,OMR, Opp AKDR Golf Village Chennai 600 097.</a></li>
                            </ul>
                </div>
                </div>
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe
                        class="embed-responsive-item" target="_blank" rel="noopener noreferrer"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62204.59126240308!2d80.199425!3d12.985474!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52675cc1b592db%3A0xf8f2f9dd6546a7ed!2sCookscape%20Factory!5e0!3m2!1sen!2sus!4v1727506424348!5m2!1sen!2sus"
                        ></iframe>
                        </div>
                </div>
            </div>

            <div class="row contact-panel-2">
            <h3 class="head-office"><span className='cookscape'>COIMBATORE</span></h3>
              
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d125322.09019483348!2d76.954471!3d11.014952!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858fcbd06142b%3A0xb71bb71a8d58d01f!2sCookscape%20Coimbatore!5e0!3m2!1sen!2sus!4v1733490931356!5m2!1sen!2sus"></iframe>
                        </div>
                </div>
                <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">1095, 1st Floor, Mettupalayam Road, Vadakovai, RS Puram West, Next to Dr. Agawal Eye Hospital Coimbatore, 641002.</a></li>
                            </ul>
                </div>
                </div>
            </div>

            <div class="row contact-panel-2">
            <h3 class="head-office"><span className='cookscape'>BANGALORE</span></h3>
              <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">No.2014,First floor, Sector B, HIG 3rd Stage, Yelahanka New Town, Yelahanka Main Road, Bangalore - 560 064.</a></li>
                            </ul>
                </div>
                </div>
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d124407.71426315504!2d77.689363!3d12.988406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1171f4b734eb%3A0xb7474349379498d8!2sCookscape!5e0!3m2!1sen!2sus!4v1733491003706!5m2!1sen!2sus"></iframe>
                        </div>
                </div>
            </div>

            <div class="row contact-panel-2">
            <h3 class="head-office"><span className='cookscape'>VIJAYAWADA</span></h3>
              
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d122415.00975516657!2d80.654971!3d16.502401!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fac801e47cf3%3A0xaef4c7f85ec0c3ce!2s59-8-3%2F1%2C%20Gayatri%20Nagar%2C%20Krishna%20Nagar%2C%20Benz%20Circle%2C%20Vijayawada%2C%20Andhra%20Pradesh%20520010%2C%20India!5e0!3m2!1sen!2sus!4v1733490844200!5m2!1sen!2sus"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                </div>
                <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">59-8-3, Potluri Venkteswara Rao St, Gayathri Nagar, Vijayawada, 520008.</a></li>
                            </ul>
                </div>
                </div>
            </div>

            <div class="row contact-panel-2">
            <h3 class="head-office"><span className='cookscape'>FACTORY ADDRESS</span></h3>
              <div class="col-md-6">
                <div class="contact-link mt-5">
                            <ul>
                                <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="">+91-91500 05679</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="">info@cookscape.com</a></li>
                            </ul>
                </div>

                <div class="contact-link">
                            <ul>
                                <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <a href="">38/103 1st Floor, Thoraipakkam ,OMR, Opp AKDR Golf Village Chennai 600 097.</a></li>
                            </ul>
                </div>
                </div>
                <div class="col-md-6">
                        <div class="ratio ratio-16x9">
                        <iframe
                        class="embed-responsive-item" target="_blank" rel="noopener noreferrer"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62204.59126240308!2d80.199425!3d12.985474!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52675cc1b592db%3A0xf8f2f9dd6546a7ed!2sCookscape%20Factory!5e0!3m2!1sen!2sus!4v1727506424348!5m2!1sen!2sus"
                        ></iframe>
                        </div>
                </div>
            </div>
          </div>
        </div>
        </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
