import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog60 from '../images/blogs/blog-images/cookscape-blog-60 .webp';
import arfathima from '../images/blogs/architects/Cookscape-Architect-Moizu-Fathima .webp';
const Blog6j = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>FACTORS TO CONSIDER WHILE CHOOSING AN INTERIOR DESIGN COMPANY</h1>
                        <img src={blog60} alt="cookscape-design-ideas" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>Designing a Home is one of the greatest accomplishments of a common man.</p>
                        <p className='blog-page-para'>But, while it is the case, making decisions with regards to the interiors of your home is probably even more important and a tiresome process. The reason being that the outcome rarely matches your expectations.</p>
                        <p className='blog-page-para'> Getting a well-designed interior is never difficult if you really hire the right person to do the job. Hence to get a good outcome you would need to carefully choose a good designer who understands your taste and style and will be able to give you your sweet home of your dreams.</p>
                        <p className='blog-page-para'> Some of the Major factors have been classified below in detail to help you choose the right interior designer.  </p>
                        <h2 className='blog-page-title-2 pt-3'>1. Quality</h2>
                        <p className='blog-page-para'> Interior design projects are a Lifetime Investment. Hence money must be spent well, for that reason it is always wise to go with good quality products along with aesthetical beauty. This will certainly help you achieve your dream home interiors which will last for a lifetime. </p>
                        <h2 className='blog-page-title-2 pt-3'>2. Accreditation</h2>
                        <p className='blog-page-para'> Check the Origin of the company and its certification, which proves their reality and How experienced they are. </p>
                        <h2 className='blog-page-title-2 pt-3'>3. Value</h2>
                        <p className='blog-page-para'> Go along with your budget…it will be better if the Interior design provides you the design according to your budget. </p>
                        <h2 className='blog-page-title-2 pt-3'>4. Experience</h2>
                        <p className='blog-page-para'> Make sure the Interior Design company you choose has thrived for more than 10 years in the design industry which proves their reputation. </p>
                        <h2 className='blog-page-title-2 pt-3'>5. Style</h2>
                        <p className='blog-page-para'> Go through the company’s previous works to better understand their clientele, style of work and quality of work. </p>
                        <h2 className='blog-page-title-2 pt-3'>6. Time Frame</h2>
                        <p className='blog-page-para'> Reasonable time frame is what u should look for and plan accordingly, the point you need to analyses is “Short Timeline may give no good outcome, and Long Time line may have reason”!!! </p>
                        <h2 className='blog-page-title-2 pt-3'>7. Aesthetics</h2>
                        <p className='blog-page-para'>Usually, the aesthetic factors gives you appealing outcome, which is also important to have lovely surrounding you live in, but at the same time concentrate more on the decors which gives both beauty and life for Interior design you do using material.</p>
                        <img src={arfathima} alt="Architect-Moizu-Fathima" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Moizu Fathima</h1>
                      </div>
                    </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6j;
