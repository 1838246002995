import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog67 from '../images/blogs/blog-images/cookscape-blog-67 .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
const Blog7h = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>ESSENTIAL INTERIOR DESIGN TIPS FOR RENTAL PROPERTY</h1>
                        <img src={blog67} alt="cookscape-essential-interior-design-tips" class="img-fluidpt-3"/>
                        <p className='blog-page-para'>Interior is not just regarding creating an ambiance or about the aesthetics.</p>
                        <p className='blog-page-para'> The Interior is about creating a usable space considering the circulation so that the life of people who are going to use that house or flat is easy and comfortable.</p>
                        <p className='blog-page-para'> Most people do the interior for the house where they are going to live, but it is a generosity that a human should have for another human, where when we are allowing another person to stay in our house under a rental agreement we create a space for them too.</p>
                        <p className='blog-page-para'> A most important point to be considered when we do the interior for the rental purpose is the following:</p>
                        <p className='blog-page-para'> 1. Expense should not be very much; as we are not sure how the person is going to maintain the product (Keeping in mind the owner should not be in loss).</p>
                        <p className='blog-page-para'> 2. The same time materials combination should be of a good grade so that it will withstand being roughly used also (making a sure person is comfortable and in future owner doesn’t have any additional expense in a later stage).</p>
                        <p className='blog-page-para'> 3. While designing factors should be considered that the person who is going to move in will have their own stuff and they will need space in the house for keeping those stuffs.</p>
                        <p className='blog-page-para'> 4. While designing the kitchen, we should avoid drawer kids of storage spaces because drawers get affected very easily if roughly used.</p>
                        <p className='blog-page-para'> 5. In the kitchen, we should incorporate most of the Solid storage spaces which can withstand more loads and will provide sufficient space for storage.</p>
                        <p className='blog-page-para'> 6. We should avoid providing Stainless steel storage as it might get rusted in case not maintained properly and would be an additional expense for the owner in case the person moves out and another person moves in.</p>
                        <p className='blog-page-para'> 7. While choosing colors we should avoid choosing bright colors as it may not be comfortable for the person going to stay, choosing a bit dull and wooden patter options would be suggestible.</p>
                        <p className='blog-page-para'> 8. Going glossy for kitchen and matt for other units would be a suggestion, but in case of budget, matt will be even suggestible for the kitchen.</p>
                        <p className='blog-page-para'> Creating a comfortable space for the person is very simple when we imagine being in their place, so while designing we will design it for us. But while doing the same for rental purpose a designer has to consider the owner as well as the person, because it has to be a win for both at the same time in case the owner plans to move in, in the same house he can just add some more units so our design should be futuristic too.</p>
                        <img src={arsaranya} alt="Architect-Saranya" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'> Architect Saranya</h1>
                      </div>
                  </div>      

  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog7h;
