import React from 'react';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import kitchen11 from '../images/services/kitchen/kitchen11 .webp';
import kitchen12 from '../images/services/kitchen/kitchen12 .webp';
import kitchen13 from '../images/services/kitchen/kitchen13 .webp';
import kitchen14 from '../images/services/kitchen/kitchen14 .webp';
import kitchen15 from '../images/services/kitchen/kitchen15 .webp';
import kitchen16 from '../images/services/kitchen/kitchen16 .webp';
const Entertainmentunit = () => {
  return (
    <>
        <div className="container-fluid services-breadcrumb">
            <h3 className="cookscape-services-title">Cookscape Entertainment Unit Ideas</h3>
        </div>
    <div className="container-fluid aboutus">
        <div className="container ">
            <h1 className="interior-title pt-5">Entertainment Unit Designs</h1>
            <p className="interior-para pt-3">An entertainment unit is no longer an unnecessary luxury in the house; it has become a must-have element in modern homes. It serves as the focal point in every living room, housing TV sets, gaming consoles, speakers, and all related paraphernalia while beautifying the place. With the appropriate design and good functional making, an entertainment unit transforms the living room into a stylish and well-customized space to relax and entertain.</p>
        </div>
        <div className="container pt-5">
            <div className="row">
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={kitchen11} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Wall-Mounted Entertainment Units </h1>
                        <p className="interior-para p-3">Wall-mounted entertainment units fit quite well with a modern-day home, representing a sleek and space-saving option. These units are attached to the wall and hence leave the floor area free, making the room feel much bigger. They are ideal for small spaces or minimalist designs as they present a clear look while hiding all the cables and wires. The feature of having opted for this kind often includes additional shelves and cabinets for storing gadgets, DVDs, and decorative items.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={kitchen12} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Freestanding Entertainment Units
                        </h1>
                        <p className="interior-para p-3">Freestanding units are a traditional type commonly preferred by people interested in the movement of furniture. These types of entertainment units sit directly on the floor and can either come large or small with plenty of storage options in the form of shelves, drawers, and cabinets. Unlike wall-mounted models, freestanding ones don't require wall installation and can easily fit into rental spaces. Freestanding units are ideal for people who are fond of their versatility, for they can just be moved around depending on the new layout or changes in décor.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={kitchen13} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Modular Entertainment Units</h1>
                        <p className="interior-para p-3">Modular entertainment units are extremely flexible and offer a great way of amending the layout and the parts to meet their demands. These types tend to feature adjustable shelves, movable shelves, and perhaps even modular configurations, so they can change with your needs along the way. Whether you need extra room for books, or gaming consoles on top of decor choices, modular units are simple, flexible, and excellent options. Such units benefit larger living rooms </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={kitchen15} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Corner entertainment units
                        </h1>
                        <p className="interior-para p-3">Corner entertainment units fit into the dead corners of the living room, offering great space-saving options. These compact units maximize utility while enhancing function in the back corners of the room. Although smaller in size, such entertainment units can accommodate your gaming consoles, TVs, and decorative items. Some corner units come with extended storage shelves, making them ideal for rooms with unconventional layouts or tiny apartments.</p>
                    </div>
                </div>
            </div>
        <div className="container-fluid services p-5">
            <h3 className="services-title-2">Type of Entertainment Unit Designs</h3>
                    <div className="row services-row">
                        <div className="col-md-4 services-col">
                            <p className="icon"><i class="fa fa-cube" aria-hidden="true"></i></p>
                            <h2 className="services-title-3">Corner wardrobe</h2>
                            <p className="services-para">This is very versatile in terms of maximizing corner space while maintaining an efficient work triangle.
                            ​</p>
                         </div>

                        <div className="col-md-4 services-col">
                            <p className="icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></p>
                            <h2 className="services-title-3">Floating entertainment units
                            </h2>
                            <p className="services-para">Floating entertainment units give your living space a modern, elegant look. These wall-mounted designs look as though they are floating,
                ​           </p>
                        </div>

                        <div className="col-md-4 services-col">
                            <p className="icon"><i class="fa fa-building" aria-hidden="true"></i></p>
                            <h2 className="services-title-3">Mirrored Wardrobe Design</h2>
                            <p className="services-para">An efficient and sleek layout that makes two parallel countertops to streamline the act of cooking.​</p>
                        </div>
                    </div>
        </div>
        <div className='container'>
            <h3 class="services-title-2">Entertainment Unit Gallery</h3>
                <div class="row justify-content-center">
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={kitchen11} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={kitchen12} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={kitchen13} alt="" class="img-fluid"/>
                        </a>
                    </div>
                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={kitchen14} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={kitchen15} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={kitchen16} alt="" class="img-fluid"/>
                        </a>
                    </div>
                </div>

                <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleLightboxLabel">Entertainment Unit Gallery</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div id="lightboxExampleCarousel" class="carousel slide">
                                    <div class="carousel-inner ratio ratio-16x9 bg-dark">
                                        <div class="carousel-item text-center active">
                                            <img src={kitchen11} alt="" class="img-fluid mh-100"/>
                                        </div>
                                        <div class="carousel-item text-center">
                                            <img src={kitchen12} alt="" class="img-fluid mh-100"/>
                                        </div>
                                        <div class="carousel-item text-center">
                                            <img src={kitchen13} alt="" class="img-fluid mh-100"/>
                                        </div>
                                        <div class="carousel-item text-center">
                                            <img src={kitchen14} alt="" class="img-fluid mh-100"/>
                                        </div>
                                        <div class="carousel-item text-center">
                                            <img src={kitchen15} alt="" class="img-fluid mh-100"/>
                                        </div>
                                        <div class="carousel-item text-center">
                                            <img src={kitchen16} alt="" class="img-fluid mh-100"/>
                                        </div>
                                    </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Entertainmentunit;
