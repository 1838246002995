import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog12 from '../images/blogs/blog-images/cookscape-blog-12 .webp';
import blog12a from '../images/blogs/blog-images/cookscape-blog-12a .webp';
import arsunitha from '../images/blogs/architects/Cookscape--Architect-sunitha .webp';
const Blog2b = () => {
        return (
                <>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>Why Less Is More When It Comes To Home Interiors?</h1>
                      <img src={blog12} alt="cookscape-home-interior" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>In inside planning the idea of “toning it down would be ideal” is tied in with making straightforward and effective insides. Straightforwardness is the way to accomplishing this. </p>
                      <p className='blog-page-para'>Perhaps the best thing about enlivening a house is the capacity to fit it to your own taste and fashion awareness. There’s no set-in-stone approach to your inside plan with patterns changing constantly, and recent fads address an entire host of potential outcomes as opposed to having the entire inside plan process directed by spending plan and space restrictions. </p>
                      <h2 className='blog-page-title-2 pt-3'>What is Minimalism?</h2>
                      <p className='blog-page-para'>Unmistakable, cold, and exhausting? Basic, smooth and provocative? Despite how you view moderate inside plan, there’s something else to this generally embraced idea besides what the greater part of us think. Diving into the hypothesis of “toning it down would be best,” we really want to revaluate moderation as not a plain white box or cool ultra-modern space, but as an idea that at the same time features an extraordinary utilitarian plan while masterfully making a worked on lifestyle choice. </p>
                      <h2 className='blog-page-title-2 pt-3'>How to EMBRACE it?</h2>
                      <p className='blog-page-para'>Regardless of whether you are a conservative and love your bends and ornaments, you can embrace a few fundamentals of moderation for a blissful and solid home. The following are a couple of tips: </p>
                      <p className='blog-page-para'> 1. De-mess your home </p>
                      <p className='blog-page-para'>2. Streamline your furnishings </p>
                      <p className='blog-page-para'>3. Simplify your shading plan</p>
                      <p className='blog-page-para'> 4. Focus on work</p>
                      <h2 className='blog-page-title-2 pt-3'>Less Is More</h2>
                      <p className='blog-page-para'> A room that is roomy and offers a peaceful air is continuously quieting the psyche. Moderate utilization of furniture and different articles in home stylistic theme makes insides breathable and offers great wellbeing and bliss to its occupants.</p>
                      <p className='blog-page-para'> You name it, let’s paint them…</p>
                      <img src={blog12a} alt="cookscape-home-interior" class="img-fluid pt-3"/>
                      <p className='blog-page-para'> Following are not many tips on accomplishing the “toning it down would be best” idea.</p>
                      <h2 className='blog-page-title-2 pt-3'>Add another tone</h2>
                      <p className='blog-page-para'>  Divider paint assumes a critical part in changing the vibe of a room. Dim tones give a minimal shift focus over to the room while light tones have a contrary response making the room look splendid and extensive. Go with nonpartisan tones, for example, ivory, dim, ecru, and beige to give an extensive impact to the room.</p>
                      <h2 className='blog-page-title-2 pt-3'>Hold the significant stuff</h2>
                      <p className='blog-page-para'>  “Less Is More” idea must do a great deal with the items set in inside. Zero in on the usefulness instead of upgrade. Inquire as to whether you truly require this? Sticking to furniture and different objects of home stylistic layout which are excessive will just add mess to the room. Such homes need complexity and excellence. Along these lines, add that vibe great element by disposing of pointless things.</p>
                      <h2 className='blog-page-title-2 pt-3'>Clear a path for regular light</h2>
                      <p className='blog-page-para'>  Ventilation working with normal light and air adequately should be the essential goal of a home. Cross-ventilation gives the room an unadulterated and new appearance. You can see it on the off chance that you see that when a room doesn’t get sufficient daylight and air, it looks little and soiled.</p>
                      <h2 className='blog-page-title-2 pt-3'>Improvise your furnishings</h2>
                      <p className='blog-page-para'>  Cumbersome furniture is the principal offender in adding to space mess. The opportunity for development is extraordinarily limited as a result of them. Continuously gauge the size and aspect of the room and afterward select the furnishings. Various reasons furniture can assist you with diminishing the mass and space required.</p>
                      <p className='blog-page-para'>  “Moderation permits some different options from the space to be the concentration. For instance, individuals in the space or the view from the window may be a higher priority than the room’s enrichment.</p>
                      <p className='blog-page-para'>  Why “Calming down would be great” When It Comes To Home Interiors</p>
                      <p className='blog-page-para'> Show: In inside arranging “quieting down would be ideal” is connected to making clear and successful internal parts. Ease is the best approach to achieving this.</p>
                      <p className='blog-page-para'> Likely the best thing about enhancing a house is the ability to fit it to your own taste and impulse with respect to form. There’s no firmly established way to deal with your inside arrangement with designs evolving continually, and late trends address a whole host of potential results instead of having the whole inside arrangement process coordinated by monetary arrangement and space constraints.</p>
                      <h2 className='blog-page-title-2 pt-3'>Make a way for ordinary light</h2>
                      <p className='blog-page-para'> Ventilation working with ordinary light and air sufficiently ought to be the fundamental objective of a home. Cross-ventilation gives the room a pure and new appearance. You can see it if you see that when a room doesn’t get adequate light and air, it looks close to nothing and grimy.</p>
                      <h2 className='blog-page-title-2 pt-3'>Off the cuff your goods</h2>
                      <p className='blog-page-para'> Monstrous furniture is the essential wrongdoer in adding to space wreck. The chance of improvement is immensely restricted because of them. Ceaselessly check the size and part of the room and thereafter select the decorations. Various explanations of furniture can help you with lessening the mass and space required.</p>
                      <p className='blog-page-para'> “Balance permits a substitute decision from the space to be the concentration. For example, people in the space or the view from the window might be a higher need than the room’s advancement”.</p>
                      <img src={arsunitha} alt="Architect-Sunitha" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Sunitha</h1>
                    </div>
                  </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog2b;
