import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog55 from '../images/blogs/blog-images/cookscape-blog-55 .webp';
import blog55a from '../images/blogs/blog-images/cookscape-blog-55a .webp';
import armeena from '../images/blogs/architects/Cookscape-Architect-Meena .webp';
const Blog6e = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>WHY DOES AN INTERIOR DESIGNER MATTER?</h1>
                        <img src={blog55} alt="cookscape-interior-designer-matter" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>An interior designer is one who is creative in mind, style in design and approach. Every designer has visual foot prints in Interior Design field. He discovers from the culture and brings essence into the life, Creates a vibe into the space through design and ideas, One who give more than you expected with interior safety of a building. An interior designers are creating a life style Fashion to a living space by understanding a small thing, and incorporate them in a big space for its aesthetic beauty.</p>
                        <p className='blog-page-para'> An interior designer matters because he/she is the one who thinks out of the box with astonishing concepts and blends it with the building. A space created by an interior designer is a feast to the viewers.</p>
                        <h2 className='blog-page-title-2 pt-3'>WHAT AN INTERIOR DESIGNER DO?</h2>
                        <img src={blog55a} alt="cookscape-interior-designer-matter" class="img-fluid pt-3"/>
                        <p className='blog-page-para'> An interior designer is one who works with the client to satisfy his requirements and needs in an aesthetic view. The created space should be attractive and functional. The interior designer uses Light as a major element. He/ she Plays with Lightings, Flooring, Painting, ceiling designs combined together to create an interiors that should satisfy the client. An interior designer follows sustainability by a focus on projects to reduce energy consumption, Balance Indoor air quality without reducing aesthetics.</p>
                        <p className='blog-page-para'> Color is another major element which that creates a state of mind in a result positive or negative. Color creates a productive and welcoming space, like warm and  cool  tones,  which  are  a </p>
                        <p className='blog-page-para'> major thing in color tones. Warm tones such as red, orange and yellow can energize a space and its occupants. Cool tones like blue, green and purple color generally create relaxing and quiet atmospheres.</p>
                        <h2 className='blog-page-title-2 pt-3'>Interior designers use 7 Major elements to design </h2>
                        <h2 className='blog-page-title-2 pt-3'>SPACE </h2>
                        <p className='blog-page-para'>Space is the major fundamental element in interior design. They design in three dimensional spaces like Length, Width, and breadth. Space can be defined into two positive space and negative space. Positive space is one in which they design and negative space they left empty for the design aspect. The color also plays a key role to make the space bigger or smaller. They balance the space by keeping the furniture and decors.</p>
                        <h2 className='blog-page-title-2 pt-3'>LINE </h2>
                        <p className='blog-page-para'>Horizontal lines give a sense of stability. Interior designers use horizontal lines to make the room wider and longer. If horizontal lines exceed the room appears boring.Vertical lines create freedom. It can create an illusion of making the room taller. Vertical lines are used in entries to make it appeal well. Dynamic lines like diagonal, Zigzag and curved lines. These lines are used in the staircase and they use for energy and movement. They overpower horizontal and vertical lines.</p>
                        <h2 className='blog-page-title-2 pt-3'>FORM</h2>
                        <p className='blog-page-para'>The form is the shape of the room. Adding similar shapes can create harmony and balance.</p>
                        <h2 className='blog-page-title-2 pt-3'>TEXTURE</h2>
                        <p className='blog-page-para'>The texture is the surface or finish. Depth, Illusions, glossy, coarse can be created in texture. It can be of visual texture and actual texture. Actually can touch and feel the space or it is visually made in a three dimension space.</p>
                        <h2 className='blog-page-title-2 pt-3'>PATTERN</h2>
                        <p className='blog-page-para'>The pattern is similar to Texture. They are found in Curtains, Rugs, Carpets, Soft furnishings and also they come in a form of floral pattern, Prints, Motifs, etc.</p>
                        <h2 className='blog-page-title-2 pt-3'>LIGHT</h2>
                        <p className='blog-page-para'>The light can be of natural or man-made. They can be Task Lighting, Mood lighting or Accent lighting. Natural lighting can be taken into consideration for the openings of a building.</p>
                        <h2 className='blog-page-title-2 pt-3'>COLOR</h2>
                        <p className='blog-page-para'>They have the ability to create mood and psychology. Light color can be used in a small space to make it larger and dark color is used to make a larger dimension space into smaller.</p>
                        <img src={armeena} alt="Architect-Meena" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Meena</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6e;
