// Home.js
import React from 'react';
import siteimage1 from '../images/gallery/siteimages/cookscape-project-1 .webp';
import siteimage2 from '../images/gallery/siteimages/cookscape-project-2 .webp';
import siteimage3 from '../images/gallery/siteimages/cookscape-project-3 .webp';
import siteimage4 from '../images/gallery/siteimages/cookscape-project-4 .webp';
import siteimage5 from '../images/gallery/siteimages/cookscape-project-5 .webp';
import siteimage6 from '../images/gallery/siteimages/cookscape-project-6 .webp';

import kitchen1 from '../images/gallery/kitchen/cookscape-modular-kitchen-1.webp';
import kitchen2 from '../images/gallery/kitchen/cookscape-modular-kitchen-2.webp';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import kitchen4 from '../images/gallery/kitchen/cookscape-modular-kitchen-4.webp';
import kitchen5 from '../images/gallery/kitchen/cookscape-modular-kitchen-5.webp';
import kitchen6 from '../images/gallery/kitchen/cookscape-modular-kitchen-6.webp';

import wardrobe1 from '../images/gallery/wardrobe/cookscape-wardrobe-design-1.webp';
import wardrobe2 from '../images/gallery/wardrobe/cookscape-wardrobe-design-2.webp';
import wardrobe3 from '../images/gallery/wardrobe/cookscape-wardrobe-design-3.webp';
import wardrobe4 from '../images/gallery/wardrobe/cookscape-wardrobe-design-4.webp';
import wardrobe5 from '../images/gallery/wardrobe/cookscape-wardrobe-design-5.webp';
import wardrobe6 from '../images/gallery/wardrobe/cookscape-wardrobe-design-6.webp';

import renovation1 from '../images/gallery/renovation/cookscape-renovation-project-1.webp';
import renovation2 from '../images/gallery/renovation/cookscape-renovation-project-2.webp';
import renovation3 from '../images/gallery/renovation/cookscape-renovation-project-3.webp';
import renovation4 from '../images/gallery/renovation/cookscape-renovation-project-4.webp';
import renovation5 from '../images/gallery/renovation/cookscape-renovation-project-5.webp';
import renovation6 from '../images/gallery/renovation/cookscape-renovation-project-6.webp';

import poojaunit1 from '../images/gallery/poojaunit/cookscape-pooja-unit-1.webp';
import poojaunit2 from '../images/gallery/poojaunit/cookscape-pooja-unit-2.webp';
import poojaunit3 from '../images/gallery/poojaunit/cookscape-pooja-unit-3.webp';
import poojaunit4 from '../images/gallery/poojaunit/cookscape-pooja-unit-4.webp';
import poojaunit5 from '../images/gallery/poojaunit/cookscape-pooja-unit-5.webp';
import poojaunit6 from '../images/gallery/poojaunit/cookscape-pooja-unit-6.webp';

import kidsfurniture1 from '../images/gallery/kidsfurniture/cookscape-kids-furniture-1.webp';
import kidsfurniture2 from '../images/gallery/kidsfurniture/cookscape-kids-furniture-2.webp';
import kidsfurniture3 from '../images/gallery/kidsfurniture/cookscape-kids-furniture-3.webp';
import kidsfurniture4 from '../images/gallery/kidsfurniture/cookscape-kids-furniture-4.webp';
import kidsfurniture5 from '../images/gallery/kidsfurniture/cookscape-kids-furniture-5.webp';
import kidsfurniture6 from '../images/gallery/kidsfurniture/cookscape-kids-furniture-6.webp';

import tvunit1 from '../images/gallery/tvunit/cookscape-tvunit-1.webp';
import tvunit2 from '../images/gallery/tvunit/cookscape-tvunit-2.webp';
import tvunit3 from '../images/gallery/tvunit/cookscape-tvunit-3.webp';
import tvunit4 from '../images/gallery/tvunit/cookscape-tvunit-4.webp';
import tvunit5 from '../images/gallery/tvunit/cookscape-tvunit-5.webp';
import tvunit6 from '../images/gallery/tvunit/cookscape-tvunit-6.webp';

import crockery1 from '../images/gallery/crockery/cookscape-corckery-1.webp';
import crockery2 from '../images/gallery/crockery/cookscape-corckery-2.webp';
import crockery3 from '../images/gallery/crockery/cookscape-corckery-3.webp';
import crockery4 from '../images/gallery/crockery/cookscape-corckery-4.webp';
import crockery5 from '../images/gallery/crockery/cookscape-corckery-5.webp';
import crockery6 from '../images/gallery/crockery/cookscape-corckery-6.webp';



const Gallery = () => {
  return (
    <>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Site Gallery</h3>
    </div>
   
    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Site Images</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={siteimage1} alt="cookscape-siteimage-1" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={siteimage2} alt="cookscape-siteimage-2" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={siteimage3} alt="cookscape-siteimage-3" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={siteimage4} alt="cookscape-siteimage-4" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={siteimage5} alt="cookscape-siteimage-5" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={siteimage6} alt="cookscape-siteimage-6" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>
    
    <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel">Cookscape Gallery</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={siteimage1} alt="cookscape-siteimage-1" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={siteimage2} alt="cookscape-siteimage-2" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={siteimage3} alt="cookscape-siteimage-3" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={siteimage4} alt="cookscape-siteimage-4" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={siteimage5} alt="cookscape-siteimage-5" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={siteimage6} alt="cookscape-siteimage-6" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Modular Kitchen</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox1">
                            
                            <img data-bs-target="#lightboxExampleCarousel1" data-bs-slide-to="6" src={kitchen1} alt="cookscape-modular-kitchen-1" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox1">
                        <img data-bs-target="#lightboxExampleCarousel1" data-bs-slide-to="7" src={kitchen2} alt="cookscape-modular-kitchen-2" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox1">
                        <img data-bs-target="#lightboxExampleCarousel1" data-bs-slide-to="8" src={kitchen3} alt="cookscape-modular-kitchen-3" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox1">
                        <img data-bs-target="#lightboxExampleCarousel1" data-bs-slide-to="9" src={kitchen4} alt="cookscape-modular-kitchen-4" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox1">
                        <img data-bs-target="#lightboxExampleCarousel1" data-bs-slide-to="10" src={kitchen5} alt="cookscape-modular-kitchen-5" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox1">
                        <img data-bs-target="#lightboxExampleCarousel1" data-bs-slide-to="11" src={kitchen6} alt="cookscape-modular-kitchen-6" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>
    
    <div class="modal fade" id="exampleLightbox1" tabindex="-1" aria-labelledby="exampleLightboxLabel1" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel1">Cookscape Modular Kitchen</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel1" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={kitchen1} alt="cookscape-modular-kitchen-1" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kitchen2} alt="cookscape-modular-kitchen-2" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kitchen3} alt="cookscape-modular-kitchen-3" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={kitchen4} alt="cookscape-modular-kitchen-4" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kitchen5} alt="cookscape-modular-kitchen-5" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kitchen6} alt="cookscape-modular-kitchen-6" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel1" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel1" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Wardrobe Design</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox2">
                            
                            <img data-bs-target="#lightboxExampleCarousel2" data-bs-slide-to="0" src={wardrobe1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox2">
                        <img data-bs-target="#lightboxExampleCarousel2" data-bs-slide-to="1" src={wardrobe2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox2">
                        <img data-bs-target="#lightboxExampleCarousel2" data-bs-slide-to="2" src={wardrobe3} alt="" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox2">
                        <img data-bs-target="#lightboxExampleCarousel2" data-bs-slide-to="3" src={wardrobe4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox2">
                        <img data-bs-target="#lightboxExampleCarousel2" data-bs-slide-to="4" src={wardrobe5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox2">
                        <img data-bs-target="#lightboxExampleCarousel2" data-bs-slide-to="5" src={wardrobe6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleLightbox2" tabindex="-1" aria-labelledby="exampleLightboxLabel2" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel2">Cookscape Wardrobe Gallery</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel2" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={wardrobe1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={wardrobe2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={wardrobe3} alt="" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={wardrobe4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={wardrobe5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={wardrobe6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel2" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel2" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Renovation Design</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox3">
                            
                            <img data-bs-target="#lightboxExampleCarousel3" data-bs-slide-to="0" src={renovation1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox3">
                        <img data-bs-target="#lightboxExampleCarousel3" data-bs-slide-to="1" src={renovation2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox3">
                        <img data-bs-target="#lightboxExampleCarousel3" data-bs-slide-to="2" src={renovation3} alt="" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox3">
                        <img data-bs-target="#lightboxExampleCarousel3" data-bs-slide-to="3" src={renovation4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox3">
                        <img data-bs-target="#lightboxExampleCarousel3" data-bs-slide-to="4" src={renovation5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox3">
                        <img data-bs-target="#lightboxExampleCarousel3" data-bs-slide-to="5" src={renovation6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleLightbox3" tabindex="-1" aria-labelledby="exampleLightboxLabel3" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel3">Cookscape Renovation Gallery</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel3" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={renovation1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={renovation2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={renovation3} alt="" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={renovation4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={renovation5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={renovation6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel3" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel3" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Pooja unit Design</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox4">
                            
                            <img data-bs-target="#lightboxExampleCarousel4" data-bs-slide-to="0" src={poojaunit1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox4">
                        <img data-bs-target="#lightboxExampleCarousel4" data-bs-slide-to="1" src={poojaunit2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox4">
                        <img data-bs-target="#lightboxExampleCarousel4" data-bs-slide-to="2" src={poojaunit3} alt="" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox4">
                        <img data-bs-target="#lightboxExampleCarousel4" data-bs-slide-to="3" src={poojaunit4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox4">
                        <img data-bs-target="#lightboxExampleCarousel4" data-bs-slide-to="4" src={poojaunit5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox4">
                        <img data-bs-target="#lightboxExampleCarousel4" data-bs-slide-to="5" src={poojaunit6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleLightbox4" tabindex="-1" aria-labelledby="exampleLightboxLabel4" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel4">Cookscape Pooja unit Design</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel4" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={poojaunit1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={poojaunit2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={poojaunit3} alt="" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={poojaunit4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={poojaunit5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={poojaunit6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel4" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel4" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Kids Furniture</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                            
                            <img data-bs-target="#lightboxExampleCarousel5" data-bs-slide-to="0" src={kidsfurniture1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                        <img data-bs-target="#lightboxExampleCarousel5" data-bs-slide-to="1" src={kidsfurniture2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                        <img data-bs-target="#lightboxExampleCarousel5" data-bs-slide-to="2" src={kidsfurniture3} alt="" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                        <img data-bs-target="#lightboxExampleCarousel5" data-bs-slide-to="3" src={kidsfurniture4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                        <img data-bs-target="#lightboxExampleCarousel5" data-bs-slide-to="4" src={kidsfurniture5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                        <img data-bs-target="#lightboxExampleCarousel5" data-bs-slide-to="5" src={kidsfurniture6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleLightbox5" tabindex="-1" aria-labelledby="exampleLightboxLabel5" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel5">Cookscape Kids Furniture</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel5" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={kidsfurniture1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kidsfurniture2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kidsfurniture3} alt="" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={kidsfurniture4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kidsfurniture5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={kidsfurniture6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel5" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel5" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape TVunit</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                            
                            <img data-bs-target="#lightboxExampleCarousel6" data-bs-slide-to="0" src={tvunit1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox5">
                        <img data-bs-target="#lightboxExampleCarousel6" data-bs-slide-to="1" src={tvunit2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                        <img data-bs-target="#lightboxExampleCarousel6" data-bs-slide-to="2" src={tvunit3} alt="" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                        <img data-bs-target="#lightboxExampleCarousel6" data-bs-slide-to="3" src={tvunit4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                        <img data-bs-target="#lightboxExampleCarousel6" data-bs-slide-to="4" src={tvunit5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                        <img data-bs-target="#lightboxExampleCarousel6" data-bs-slide-to="5" src={tvunit6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleLightbox6" tabindex="-1" aria-labelledby="exampleLightboxLabel6" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel6">Cookscape TV unit</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel6" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={tvunit1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={tvunit2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={tvunit3} alt="" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={tvunit4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={tvunit5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={tvunit6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel6" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel6" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid aboutus">
    <h1 className='gallery-title text-center pt-3'>Cookscape Crockery Unit</h1>
        <div class="container pt-5">
            <div class="row justify-content-center">
                
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox7">
                            
                            <img data-bs-target="#lightboxExampleCarousel7" data-bs-slide-to="0" src={crockery1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox7">
                        <img data-bs-target="#lightboxExampleCarousel7" data-bs-slide-to="1" src={crockery2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox7">
                        <img data-bs-target="#lightboxExampleCarousel7" data-bs-slide-to="2" src={crockery3} alt="" class="img-fluid"/>
                        </a>
                        
                    </div>


                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox7">
                        <img data-bs-target="#lightboxExampleCarousel7" data-bs-slide-to="3" src={crockery4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                        <img data-bs-target="#lightboxExampleCarousel7" data-bs-slide-to="4" src={crockery5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox6">
                        <img data-bs-target="#lightboxExampleCarousel7" data-bs-slide-to="5" src={crockery6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleLightbox7" tabindex="-1" aria-labelledby="exampleLightboxLabel7" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleLightboxLabel7">Cookscape Crockry Unit</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="lightboxExampleCarousel7" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                
                                <img  src={crockery1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={crockery2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={crockery3} alt="" class="img-fluid mh-100"/>
                            </div>

                            <div class="carousel-item text-center">
                            <img  src={crockery4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={crockery5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                            <img  src={crockery6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel7" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel7" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
