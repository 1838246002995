import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog56 from '../images/blogs/blog-images/cookscape-blog-56 .webp';
import blog56a from '../images/blogs/blog-images/cookscape-blog-56a .webp';
import blog56b from '../images/blogs/blog-images/cookscape-blog-56b .webp';
import blog56c from '../images/blogs/blog-images/cookscape-blog-56c .webp';
import arapsana from '../images/blogs/architects/Cookscape--Architect-Apsana .webp';
const Blog6f = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                  <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>TIPS ON CHOOSING INTERIOR CONTRACTOR FOR RESIDENCE!</h1>
                      <img src={blog56} alt="cookscape-interior-design" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>You already have an idea for Interior Design for your home. However without the right type of Interior Designer this will not fructify in real life. A right Interior Designer is needed to fulfill your needs and wants so that you can live in your dream house.</p>
                      <h2 className='blog-page-title-2 pt-3'>What is the difference between an Interior Design consultant and a contractor?</h2>
                      <p className='blog-page-para'> An Interior Designer is the one who gives design concepts based on your needs and requirements. The Interior design consultant must visualize that designs and must then apply it so that it fits well with your wants. Professional Interior design consultants also think about the suitable selection of materials to the expectations.</p>
                      <p className='blog-page-para'> Meanwhile, Interior Contractors are the executors of the design drawings done by the design consultant. As the Interior contractor has to approve renovations planned according to the design drawn. The Main task for an Interior Contractor is to plan and execute and also to coordinate between owner, contractor and the building contractor.</p>
                      <p className='blog-page-para'> The role of an Interior Contractor is to direct the function, dimension and also to fulfill the needs of the owner. The Interior contractor can also produce the furniture according to the concept of the Interior design. The work done by the Interior Contractor will be well detailed so that it will be ready to inhabit by their owners. </p>
                      <img src={blog56a} alt="cookscape-interior-design" class="img-fluid pt-3"/>
                      <p className='blog-page-para'> major thing in color tones. Warm tones such as red, orange and yellow can energize a space and its occupants. Cool tones like blue, green and purple color generally create relaxing and quiet atmospheres.</p>
                      <h2 className='blog-page-title-2 pt-3'>Believe in Feelings </h2>
                      <img src={blog56b} alt="cookscape-interior-design" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Home is the extension of your personality and also your preferences. This is the reason to create rooms keeping specific requirements in mind. While we generally consider the aesthetic apart more important to consider the psychology of interior design also. The feeling of trust and comfort of communication ensures an important co-operation, especially in the long run.</p>
                      <h2 className='blog-page-title-2 pt-3'>Integrity check </h2>
                      <p className='blog-page-para'>Pay attention to a number of certifications and quality of materials offered by an organization, company and legal entity and also Guarantees offered by the contractor. Requesting the interior organization to showcase some of their already completed sites as well as ongoing projects can also be helpful to generate ideas. Checking the portfolio of the Interior Contractor is done to make sure the company has the experience to handle such specific projects.</p>
                      <h2 className='blog-page-title-2 pt-3'>Cost Effective</h2>
                      <p className='blog-page-para'>Working on the appropriate cost is also another important thing. For this reason, the interior contractor will provide a detailed estimate specifically made for a particular type of construction and he can also provide an phase wise installation strategy. Finally the site working drawings must be thoroughly checked to ensure a smooth and hassle free installation of interior furnishing.</p>
                      <img src={blog56c} alt="cookscape-interior-design" class="img-fluid pt-3"/>
                      <h2 className='blog-page-title-2 pt-3'>Consultant services from the same company</h2>
                      <p className='blog-page-para'>This is to clarify misunderstanding during the project from the company. Professional interior consultants can review at the time of application and make adjustments, so that there is no need to make changes to the concepts, ideas and images as a whole. It is intended that the end result remains the same in the field as the design drawings that have been made.</p>
                      <img src={arapsana} alt="Architect-Apsana" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'>Architect Apsana</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6f;
