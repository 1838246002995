import React from 'react';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import pimg1 from '../images/services/kitchen/modularkitchen .webp';
import kitchen11 from '../images/services/kitchen/kitchen11 .webp';
import kitchen12 from '../images/services/kitchen/kitchen12 .webp';
import kitchen13 from '../images/services/kitchen/kitchen13 .webp';
import kitchen14 from '../images/services/kitchen/kitchen14 .webp';
import kitchen15 from '../images/services/kitchen/kitchen15 .webp';
import kitchen16 from '../images/services/kitchen/kitchen16 .webp';
import kitchen17 from '../images/services/kitchen/kitchen17 .webp';
import kitchen18 from '../images/services/kitchen/kitchen18 .webp';
const Kitchen = () => {
  return (
    <>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Kitchen Ideas</h3>
    </div>
    <div className="container-fluid aboutus">
                <div className="container ">
                        <h1 className="interior-title pt-5">All you can think about
                        Modular Kitchen Design</h1>
                        <p className="interior-para pt-3">Cookscape is a well-known brand in the field of manufacturing and marketing modern kitchens for the last 30+ years. Over the years, it has been widely recognized as a one-stop store for home interiors offering comprehensive solutions—from concept to execution.
                        <br/>
                        <br/>
                        We offer the entire range of well-designed, functional home furnishing solutions, including modular kitchens, wardrobes, study units, and other modular woodwork, all designed to be functional and user-friendly.</p>
                </div>
                <div className='container'>
                <div className='row pt-5'>
                    <div className='col-md-6'>
                        <div className='group pt-5'>
                            <img src={pimg1} alt="" class="img-fluid rounded"/>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <p class="website-title pt-5">Kitchen Ideas</p>
                            <h1 class="website-title-main">Modular Kitchen</h1>
                            <p class="website-para">A modular kitchen is a modern and efficient kitchen design, elegant and stylish, enhancing the functionality of the kitchen area while maximizing space. A modular kitchen is a pre-assembled cabinet module or unit. These units can be easily assembled and disassembled or even be customized to fit the preferred kitchen layout. It allows different units for storage, cooking, or cleaning. Thus, the kitchen becomes organized, easy to use, and comfortable.
                            <br/>
                            <br/>
                            A major advantage of a modular kitchen is that it is flexible. You can have a modular kitchen whether you are staying in a compact apartment or have an expansive home. </p>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen11} alt="cookscape-traditional-kitchen" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Traditional Kitchen</h1>
                                    <p className="interior-para p-3">Bring timeless charm to your kitchen with detailed woodwork and classic finishes, creating a warm and inviting space.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen12} alt="cookscape-matt-finish" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Matt finish </h1>
                                    <p className="interior-para p-3">Create a modern, clean look with smooth, matt surfaces that resist smudges and fingerprints, perfect for a simple and stylish kitchen.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen13} alt="cookscape-glossy-finish" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Glossy Finish</h1>
                                    <p className="interior-para p-3">Brighten up your kitchen with glossy, reflective surfaces that add luxury and make small spaces feel larger. Stylish, modern, and easy to maintain.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen15} alt="cookscape-vanity-pu-finish" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Vanity PU Finish</h1>
                                    <p className="interior-para p-3">Choose a sleek, high-quality Polyurethane (PU) finish for a durable and polished look. This premium coating adds a touch of luxury to any kitchen.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen16} alt="cookscape-alumix" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Alumix</h1>
                                    <p className="interior-para p-3">Combine strength and modernity with this aluminum-based finish. Lightweight yet durable, it offers a sleek, industrial look that’s easy to maintain.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen17} alt="cookscape-iris" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Iris</h1>
                                    <p className="interior-para p-3">Add a pop of personality with vibrant colors and bold designs. This finish suits contemporary kitchens and ensures a lively, dynamic atmosphere.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen18} alt="cookscape-amaryllis" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Amaryllis</h1>
                                    <p className="interior-para p-3">Achieve a seamless and sophisticated look with muted tones and smooth surfaces. Ideal for minimalistic kitchen designs with Enchanced elegance.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="interior-col">
                                    <img src={kitchen12} alt="cookscape-acrylic" class="img-fluid rounded"/>
                                    <h1 className="interior-title pt-3">Acrylic</h1>
                                    <p className="interior-para p-3">Experience a glossy, mirror-like shine that’s both stylish and functional. This scratch-resistant finish is perfect for a high-end, modern kitchen.</p>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid services p-5">
                            <h3 className="services-title-2">Type of Kitchen Ideas</h3>
                            <div className="row services-row">
                                <div className="col-md-4 services-col">
                                    <p className="icon"><i class="fa fa-cube" aria-hidden="true"></i></p>
                                    <h2 className="services-title-3">L-Shaped Modular Kitchen Design</h2>
                                    <p className="services-para">This is very versatile in terms of maximizing corner space while maintaining an efficient work triangle.
                            ​         </p>
                                </div>
                                <div className="col-md-4 services-col">
                                    <p className="icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></p>
                                    <h2 className="services-title-3">U-Shaped Modular Kitchen Design</h2>
                                    <p className="services-para">A wider configuration generally allowing ample storage and better workspace.
                                    ​</p>
                                </div>
                                <div className="col-md-4 services-col">
                                    <p className="icon"><i class="fa fa-building" aria-hidden="true"></i></p>
                                    <h2 className="services-title-3">Parallel Kitchen Design</h2>
                                    <p className="services-para">An efficient and sleek layout that makes two parallel countertops to streamline the act of cooking.​</p>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col-md-4 services-col">
                                        <p className="icon"><i class="fa fa-bars" aria-hidden="true"></i></p>
                                        <h2 className="services-title-3">Open Modular Kitchen</h2>
                                        <p className="services-para">A modern design that marries the kitchen seamlessly with living spaces for a contemporary feel.​</p>
                                    </div>
                                    <div className="col-md-4 services-col">
                                        <p className="icon"><i class="fa fa-home" aria-hidden="true"></i></p>
                                        <h2 className="services-title-3">Straight Modular Kitchen</h2>
                                        <p className="services-para">A compact layout with all the essentials aligning perfectly to one wall for a minimalist look.​</p>
                                    </div>
                                    <div className="col-md-4 services-col">
                                        <p className="icon"><i class="fa fa-briefcase" aria-hidden="true"></i></p>
                                        <h2 className="services-title-3">Island Modular Kitchen</h2>
                                        <p className="services-para">A luxurious design featuring a central island, which serves as both prep space and social hub.​</p>
                                    </div>
                            </div>
                        </div>
                        <div className='container'>
                            <h3 class="services-title-2">Kitchen Gallery</h3>
                            <div class="row justify-content-center">
                                <div class="row justify-content-center">
                                    <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={kitchen11} alt="" class="img-fluid"/>
                                    </a>
                                    <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={kitchen12} alt="" class="img-fluid"/>
                                    </a>
                                    <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={kitchen13} alt="" class="img-fluid"/>
                                    </a>
                                </div>
                                <div class="row justify-content-center pt-3">
                                    <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={kitchen14} alt="" class="img-fluid"/>
                                    </a>
                                    <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={kitchen15} alt="" class="img-fluid"/>
                                    </a>
                                    <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                                        <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={kitchen16} alt="" class="img-fluid"/>
                                    </a>
                                </div>
                            </div>
                            <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
                                <div class="modal-dialog modal-xl modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleLightboxLabel">Kitchen Gallery</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                    <div class="modal-body">
                                        <div id="lightboxExampleCarousel" class="carousel slide">
                                            <div class="carousel-inner ratio ratio-16x9 bg-dark">
                                                <div class="carousel-item text-center active">
                                                    <img src={kitchen11} alt="" class="img-fluid mh-100"/>
                                                </div>
                                                <div class="carousel-item text-center">
                                                    <img src={kitchen12} alt="" class="img-fluid mh-100"/>
                                            </div>
                                                <div class="carousel-item text-center">
                                                    <img src={kitchen13} alt="" class="img-fluid mh-100"/>
                                                </div>
                                                <div class="carousel-item text-center">
                                                    <img src={kitchen14} alt="" class="img-fluid mh-100"/>
                                                </div>
                                                <div class="carousel-item text-center">
                                                    <img src={kitchen15} alt="" class="img-fluid mh-100"/>
                                                </div>
                                                <div class="carousel-item text-center">
                                                    <img src={kitchen16} alt="" class="img-fluid mh-100"/>
                                                </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
    </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Kitchen;
