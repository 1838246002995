import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog62 from '../images/blogs/blog-images/cookscape-blog-62 .webp';
import argomathi from '../images/blogs/architects/Cookscape-Architect-Gomathi-Varshini .webp';
const Blog7b = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>ENHANCE HOME WITH EFFICIENT INTERIORS!</h1>
                        <img src={blog62} alt="cookscape-efficient-interiors" class="img-fluid pt-3"/>
                        <h2 className='blog-page-title-2 pt-3'>RENOVATION:</h2>
                        <p className='blog-page-para'>Home is the space where we live, school, work and dine. The smallest changes can save the spaces and make it refreshing, reviving and relaxing. Upgrade and reclaim your home from a crunched cluttered space to a sweet spot to unwind your long day.</p>
                        <p className='blog-page-para'>Enrich the spaces with a tinge of Interiors for an impact and make it efficient enough.</p>
                        <h2 className='blog-page-title-2 pt-3'>Make your KITCHEN The Perfect place for a Perfect Meal.</h2>
                        <p className='blog-page-para'>Organize the kitchen with premade modules or cabinets that are extremely versatile and designed according to your layout with a combination of Wall storages, Tall units, Floor cabinets, drawers, and accessories.</p>
                        <h2 className='blog-page-title-2 pt-3'>Stylish Modular WARDROBES</h2>
                        <p className='blog-page-para'> Storage is the most essential element in a Bedroom and designed cloth storage is the best solution for this storage need. Customize it according to your comfort and style. These wardrobes can easily fit into your unused spaces and L- Corners and maximize the space utilization. </p>
                        <h2 className='blog-page-title-2 pt-3'>Trendy TV UNIT</h2>
                        <p className='blog-page-para'> Enhance the entertainment experience by giving your Television the sophisticated storage unit with space for associated electronics and cables. A stylish and practical modern wall mounted floating TV unit or a standalone TV Unit coupled with LED strip lights creates an accent wall in the living room. </p>
                        <h2 className='blog-page-title-2 pt-3'>Crafted CROCKERY</h2>
                        <p className='blog-page-para'> A Stand alone or wall mounted ornamental furniture for articulating and adoring the Kitchen and Dining area. This simple and stylish show space is perfect for showcasing the glassware collections. </p>
                        <h2 className='blog-page-title-2 pt-3'>ART and Abstract touches</h2>
                        <p className='blog-page-para'> An artefact at home adds light and grace to the plain walls that look unfinished. Decorate the home by creating a coherent and personalized aesthetics to the walls with antiques, metal crafts, wall decor, lamps, candles, clocks, Bookend, Flower Sticks. The artefact acts as the focal point to the spaces adding more elegance to the interiors. </p>
                        <h2 className='blog-page-title-2 pt-3'>Experience a Slice of the sun in Serene BALCONIES</h2>
                        <p className='blog-page-para'> A little outside space can be a place to take a break, chill out and relax. It doesn’t take a lot to create some comfort for yourself and make even the tiniest balcony your own personal comfy spot by addition of seating, Flower pots, creepers and climbers and texture floorings, and hanging lights. </p>
                        <img src={argomathi} alt="Architect-Gomathi-Varshini" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect A.Gomathi Varshini</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog7b;
