import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog40 from '../images/blogs/blog-images/cookscape-blog-40 .webp';
import arragul from '../images/blogs/architects/Cookscape-Architect-Raghul .webp';
const Blog4j = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>How To Decor Your Home In Phases – With Time Intervals</h1>
                        <img src={blog40} alt="cookscape-decor-your-home" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>Moving into a new home can be one of life’s greatest joys, but it can also be a time of uncertainty, especially when it comes to decorating. How do you make your space look its best while reflecting your personal sense of style? Do it well and you’ll end up with a comfortable, happy home. Do it poorly and you’ll end up with a hodge-podge of furniture, fabrics and paint colors. With a little planning, and by following the same steps used by professional interior designers, you’ll have a much greater chance of success.</p>
                        <h2 className='blog-page-title-2 pt-3'>ALRIGHT, IT’S TIME FOR THE BREAKDOWN… SO WHERE DO YOU EVEN START?</h2>
                        <p className='blog-page-para'>Think of your room in layers- that will be the easiest way to process each phase of your design. Each layer makes your home cozier than the last. Think of it as a pyramid with 5 layers. Start at the bottom and work your way up in manageable steps. </p>
                        <h2 className='blog-page-title-2 pt-3'>1. Decide on which rooms or areas do you want to focus on right away:</h2>
                        <p className='blog-page-para'>Start in the room you’re looking to furnish, armed with a measuring tape and a notepad.</p>
                        <h2 className='blog-page-title-2 pt-3'>2. Decide on what Furniture You Want to Keep:</h2>
                        <p className='blog-page-para'>You can create more cooking flexibility by adding a microwave to a bank of built-in appliances. Many manufacturers offer a compact collection of microwaves, steam ovens, coffee machines etc with a 45cm height instead of traditional 60cm.</p>
                        <h2 className='blog-page-title-2 pt-3'>3. Layout the Rooms Floor Plan:</h2>
                        <p className='blog-page-para'>Once you have the measurements of your room, it’s time to put them to use with a floor plan that gives you a bird’s eye view of the entire home. Once you have the outline of the space, start experimenting with the placement of furniture, making sure that the footprint of each piece is scaled to match the size of the drawing.</p>
                        <h2 className='blog-page-title-2 pt-3'>4. Pick Your Color Palette:</h2>
                        <p className='blog-page-para'>Some people say you should pull your color palette from the flooring. Others recommend starting with a piece of art. I suggest starting with the item you’re most in love with. If that’s a rug, pull the color palette from that. If it’s an artwork you own or an outfit you adore, let that dictate the decor. If you’re madly in love with the color yellow, start there.</p>
                        <h2 className='blog-page-title-2 pt-3'>5. Paint:</h2>
                        <p className='blog-page-para'>Paint is the cheapest way to personalize a space, and it will give you the most bang for your buck. Even if you prefer white, find a wonderful white that lends atmosphere.If you’re reluctant to paint the whole place, just paint an accent wall. If you’re afraid of committing to a bold hue, choose an in-between color. I promise you it’ll look more interesting than linen white.</p>
                        <h2 className='blog-page-title-2 pt-3'>6. Control the light:</h2>
                        <p className='blog-page-para'>The ability to control light – both natural and artificial – is important. Sunlight will still usually enter the room from edges. To block it, add curtains with a blackout lining. At night, it’s helpful to have layers of lighting. An overhead light allows you to illuminate the whole room quickly, but may not do much to set the right mood.</p>
                        <h2 className='blog-page-title-2 pt-3'>7. Finish With Art and Accessories:</h2>
                        <p className='blog-page-para'>The last step to finishing any room is to add art work, but there is no one-size-fits-all approach. In a minimalist space, it might be just a few objects in a grander setup, it could involve displaying entire collections and layers of Objects d’art.</p>
                        <img src={arragul} alt="Architect-Raghul" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Raghul</h1>
                      </div>
                </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog4j;
