import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
const Privacypolicy = () => {
  return (
    <>
    <Helmet>
      <title>Privacy Policy - Cookscape</title>
    </Helmet>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Privacy Policy</h3>
    </div>
     <div className='container-fluid aboutus'>
        <div className='container p-5'>
          <h2 className='blog-page-title-2'>Privacy Policy for Cookscape Interiors Design</h2>
          <p className='blog-page-para'>Welcome to Cookscape Interiors Design. At Cookscape, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By using our website, you agree to the terms outlined in this policy.</p>
          <h2 className='blog-page-title-2'>Information We Collect:</h2>
          <h2 className='blog-page-title-2'>1. Personal Information:</h2>
          <p className='blog-page-para'>- Name</p>
          <p className='blog-page-para'>- Contact information (email address, phone number)</p>
          <p className='blog-page-para'>- Address</p>
          <h2 className='blog-page-title-2'>2. Non-Personal Information:</h2>
          <p className='blog-page-para'>- Website usage data (e.g., IP address, browser type, pages visited)</p>
          <h2 className='blog-page-title-2'>How We Use Your Information:</h2>
          <h2 className='blog-page-title-2'>1. Personal Information:</h2>
          <p className='blog-page-para'>- To provide and maintain our services</p>
          <p className='blog-page-para'>- To respond to your inquiries and provide support</p>
          <p className='blog-page-para'>- Address</p>
          <h2 className='blog-page-title-2'>2. Non-Personal Information:</h2>
          <p className='blog-page-para'>- To analyze trends and improve our website’s functionality</p>
          <h2 className='blog-page-title-2'>Information Sharing:</h2>
          <p className='blog-page-para'>We do not sell, trade, or otherwise transfer your personal information to outside parties. However, this does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</p>

          <h2 className='blog-page-title-2'>Security:</h2>
          <p className='blog-page-para'>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>

          <h2 className='blog-page-title-2'>Cookies:</h2>
          <p className='blog-page-para'>We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction.</p>

          <h2 className='blog-page-title-2'>Your Consent:</h2>
          <p className='blog-page-para'>By using our site, you consent to our privacy policy.</p>

          <h2 className='blog-page-title-2'>Changes to our Privacy Policy:</h2>
          <p className='blog-page-para'>If we decide to change our privacy policy, we will post those changes on this page.</p>
          <p className='blog-page-para'>For any questions regarding this privacy policy, please contact us at [support@cookscape.com].</p>
          <p className='blog-page-para'>Thank you for choosing Cookscape Interior Design.</p>
        </div>
      </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Privacypolicy;
