import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog8 from '../images/blogs/blog-images/cookscape-blog-8 .webp';
import argopal from '../images/blogs/architects/Cookscape-Architect-madhana-gopal .webp';
const Blog1h = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>12 Reasons to love Chalk Paint!</h1>
                        <img src={blog8} alt="cookscape-chalk-paint" class="blog-img-size pt-3"/>
                        <p className='blog-page-para'>Have you ever wondered about chalk paint?? No no not the chalkboard paint… It’s something beyond that! Here you’ll find 12 reasons love chalk paint which is a hit with designers and DIYers so you can make an informed choice for your Home Interiors! </p>
                        <p className='blog-page-para'>So, what is chalk paint technically?</p>
                        <p className='blog-page-para'>Let’s keep it short and simple, chalk paint is a versatile color treatment used by designers on furniture, fabric, and more, easily recognizable by its soft, ultra-matte finish.</p>
                        <p className='blog-page-para'>How do we apply it? </p>
                        <p className='blog-page-para'> Let’s apply 2 coats of chalk paint, let the paint dry, then finish off with wax, using a wax brush, let dry, and buff with a cotton cloth. </p>
                        <p className='blog-page-para'>Or on the other hand, for an all the more serious shine look, you can finish off your chalk paint with veneer. </p>
                        <p className='blog-page-para'>The Birth of Chalk paint… In 1990, Annie Sloan created this iconic, versatile chalk paint.</p>
                        <p className='blog-page-para'> Reasons to love them….</p>
                        <h2 className='blog-page-title-2'>1. Million Choice of colors</h2>
                        <p className='blog-page-para'> The colors of Chalk Paint are as unlimited as your imagination. We can mix more than a color with thousands of possible color combinations and come up with our own choice and style!</p>
                        <p className='blog-page-para'> A good rule to follow is the 2-to-1 ratio: 2 parts whites to 1-part color.</p>
                        <h2 className='blog-page-title-2'>2. Let’s skip the prep</h2>
                        <p className='blog-page-para'> Say bye to the sandpaper and primer. One of the biggest benefits of chalk paint is that you can skip the time-consuming, unglamorous part of painting—the prep work., all you need is a clean surface. You merely have to wipe the surface clean and the paint will adhere, leaving a smooth, shiny surface with a posh Matte finish.</p>
                        <h2 className='blog-page-title-2'>3. Easy Cleaning</h2>
                        <p className='blog-page-para'> Since the chalk paint is latex based, you can clean your paint tools with soap and water instead of mineral spirits, making clean-up fast and easy. You can also clean splatters off your skin with oil-based products. Easy right.</p>
                        <h2 className='blog-page-title-2'>4. Versatile</h2>
                        <p className='blog-page-para'> Perhaps the best thing about chalk paint is that it will take to many surfaces. “It will adhere to most everything,” says Sloan. chalk paint is in no way, shape, or form restricted to furniture. It’s incredibly versatile because it sticks to just about any surface, including walls, floors, metal and even kitchen cabinets, especially in shabby-chic or country-style decor.</p>
                        <p className='blog-page-para'> You name it, let’s paint them…</p>
                        <h2 className='blog-page-title-2'>5. Easy blending</h2>
                        <p className='blog-page-para'> I’m not looking at mixing together two paint tones to concoct an alternate tone.</p>
                        <p className='blog-page-para'> It’s about artsy techniques, blending 2 or more shades together to create variations, ombre or more designs. </p>
                        <h2 className='blog-page-title-2'>6. Distressing</h2>
                        <p className='blog-page-para'> You can trouble it before you seal or later. Furthermore, you could in fact trouble with a wet cloth or paper towel.</p>
                        <p className='blog-page-para'> One of my unmistakable methods is the ‘two-variety trouble’s — this is where you utilize two different composed colors as first and second covers, when dry you eliminate region of the paint utilizing sandpaper,” said Annie. “I suggest ‘troubling’ region of the piece that would normally wear and age to give a bona fide look. You can, obviously, do this with only one tone and sand back to uncover the first surface.” The paint dries to a matte completion, which makes a pasty appearance and gives the paint its name.</p>
                        <h2 className='blog-page-title-2'>7. Forgive and forget mistakes</h2>
                        <p className='blog-page-para'> It’s okay to have some imperfections, if you end up with some visible brush strokes, it’ll look natural instead of being an eyesore. If you do decide to sand away the brush marks, that’s completely fine. It’ll create the popular “distressed” look.</p>
                        <h2 className='blog-page-title-2'>8. Chic Matte Finish</h2>
                        <p className='blog-page-para'> The paint dries to a matte completion, which makes a white appearance and gives the paint its name. Adding a wax layer to your project deepens and protects the color, and creates a slight sheen as simple as that…</p>
                        <h2 className='blog-page-title-2'>9. Durable</h2>
                        <p className='blog-page-para'> Perhaps of the most well-known worry about chalk paint is whether it’s adequately sturdy to use on high-traffic pieces. if you take the time to seal it properly, absolutely yes.</p>
                        <h2 className='blog-page-title-2'>10. Brush it or spray it</h2>
                        <p className='blog-page-para'> Chalky paint can be applied with a brush just like traditional paint or can be sprayed to intricately designed furniture.</p>
                        <h2 className='blog-page-title-2'>11. Less Time consumption</h2>
                        <p className='blog-page-para'> How long will it take the chalk paint to dry? hardly an hour… yeah, you read it right, Expect the paint to be dry to the touch in as little as one hour.</p>
                        <p className='blog-page-para'> But still, it’s best to let the paint sit overnight before applying a second coat or distressing the finish for a better look</p>
                        <h2 className='blog-page-title-2'>12. Easy refurbishing</h2>
                        <p className='blog-page-para'> Furnitures looking old, No worries. Give them a Chic look with chalk paint and voila, furniture with fresh look is ready!</p>
                        <p className='blog-page-para'> Hassle-free renovation to home interiors.</p>
                        <p className='blog-page-para'> So, do you need any more reasons to wait, come let’s do some chalk painting together…</p>
                        <img src={argopal} alt="Architect-Madhana-Gopal" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'> Architect Madhana Gopal</h1>
                      </div>
                    </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog1h;
