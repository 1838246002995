// Home.js
import React from 'react';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
const Happenings = () => {
  return (
   <>
   <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Happenings</h3>
    </div>
    <div className="container-fluid aboutus">
      <div className="container">
         <div className="row">
            <div className="col-md-4">
              <div class="embed-responsive embed-responsive-4by3 p-3">
                <iframe class="embed-responsive-item" width="300" height="300" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/Sk7dOZgQdiU" allowfullscreen></iframe>
              </div>
            </div>
        <div className="col-md-4">
          <div class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item" width="300" height="300" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/2nkjQc6KCCY?si=N3xR7DQM-unofa27" allowfullscreen></iframe>
          </div>
        </div>
        <div className="col-md-4">
          <div class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item" width="300" height="300" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/etoD6f8sk8Q?si=cynUL7yPsP8nCk3f" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-4">
          <div class="embed-responsive embed-responsive-4by3 p-3">
            <iframe class="embed-responsive-item" width="300" height="300" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/mvn0DMnwWU0?si=jd0sN2teBi42c0xh" allowfullscreen></iframe>
          </div>
        </div>
        <div className="col-md-4">
         <div class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item" width="300" height="300" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/mf0xS1IfnyA?si=UBOhQ59sQYgPQzTv" allowfullscreen></iframe>
          </div>
        </div>

        <div className="col-md-4">
         <div class="embed-responsive embed-responsive-4by3">
  <iframe class="embed-responsive-item" width="300" height="300"target="_blank" rel="noopener noreferrer"src="https://www.youtube.com/embed/BR2bS8dgyiI?si=dqhM5aXkAreq9K3s" allowfullscreen></iframe>
</div>
        </div>

       
       
       </div>

       <div className="row mt-5">
        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-4by3 p-3">
  <iframe class="embed-responsive-item" width="300" height="300" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/5Mu2FPn0Dgo?si=KLiypp8NB0KhZs5i" allowfullscreen></iframe>
</div>
        </div>

         <div className="col-md-4">
         
        </div>

        <div className="col-md-4">
         
        </div>

       
       
       </div>
    </div>
    </div>


  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
   </>
  );
};

export default Happenings;
