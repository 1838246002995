import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog35 from '../images/blogs/blog-images/cookscape-blog-35 .webp';
import arranchana from '../images/blogs/architects/Cookscape-Architect-Ranchana .webp';
const Blog4e = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                  <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>Why to choose Surabhi Variant?</h1>
                      <img src={blog35} alt="cookscape-surabhi-variant" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>A variant that is the epitome of minimalist design. Its lacquered finish with a bright and shiny appearance not only gives the kitchen a luxurious look but also makes it an aesthetically pleasing choice. Made from sheet plywood of the highest grade Boiling Water Proof Plywood (BWP) it is of premium quality. It shutters have a Painted Plywood finish. It has a total of 3 coats of Spray Paint on its external shutters and it is also a handle-less variant that comes with a plethora of color options. Any Pastel color from Asian Paints can be applied to this variant. Most suited for wardrobes, TV. Units or Kitchens with more of Western Style cooking like Grilling or Microwaving and less of pots and pans cooking. As spicy cooking can emit more of strong fumes which can over a period of time harm the Kitchen Cabinets.</p>
                      <h2 className='blog-page-title-2 pt-3'>Key Points & Features:</h2>
                      <p className='blog-page-para'>• Highlight – Contemporary Finish and Stellar Looks</p>
                      <p className='blog-page-para'>• Kitchen Carcass Boiling Water Proof (BWP) – IS710 Grade</p>
                      <p className='blog-page-para'>• Wardrobe Carcass: Boiling Water Proof (BWP) – IS710 Grade</p>
                      <p className='blog-page-para'>• Shutter Material: HIGH Density Fiber</p>
                      <p className='blog-page-para'>• Shutter Finish: Lacquered Paint Finish</p>
                      <p className='blog-page-para'>• Primary Hardware: EBCO (12 Kg)</p>
                      <img src={arranchana} alt="Architect-Ranchana" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'>Architect Ranchana</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog4e;
