import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog5 from '../images/blogs/blog-images/cookscape-blog-5 .webp';
import blog5a from '../images/blogs/blog-images/cookscape-blog-5a .webp';
import arbhaghyasri from '../images/blogs/architects/Cookscape-Architect-bhagyashri .webp';
const Blog1e = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div> 
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>7 Amazing Wooden Ceiling Designs You Will Love!</h1>
                      <img src={blog5} alt="cookscape-wooden-ceiling-design" class="blog-img-size pt-3"/>
                      <p className='blog-page-para'>● A practical advantage is that the installation of wooden false ceiling design is easy. </p>
                      <h2 className='blog-page-title-2'>What is the Idea and Theme of Interior design?</h2>
                      <p className='blog-page-para'>● Spoilt for choice as wooden ceiling designs are available in a variety of natural patterns and textures. </p>
                      <p className='blog-page-para'>● It provides a bunch of functional benefits, in addition to uplifting the look of your home. Modern false ceiling design for hall or even office false ceiling design is a great way to conceal the jungle of electric wires, pipes and air-conditioning ducts. </p>
                      <p className='blog-page-para'>● Versatile as wood is popularly used to create false wooden ceiling design. It is significantly joined with different materials like POP, gypsum, metal, glass, and so forth. </p>
                      <h2 className='blog-page-title-2 pt-3'>1. Exquisite Lighting</h2>
                      <p className='blog-page-para'> Coordinate the advanced bogus roof plan for the corridor with the wooden ground surface. Complement the rich wooden pillars with light apparatuses. Notice how the wall tones draw out the rich brown of the wood? One more good thought for a wooden misleading roof is by utilizing the wooden overlay on one side of the parlor wall. The wooden board absolutely emphasizes the insides. </p>
                      <p className='blog-page-para'>A good rule to follow is the 2-to-1 ratio: 2 parts whites to 1-part color. </p>
                      <h2 className='blog-page-title-2 pt-3'>2. Gratifying Ambience</h2>
                      <p className='blog-page-para'>It’s stylish to coordinate the sidewall with the wooden misleading roof plan. Notice how the thin wooden strips likewise go through the sidewall? You can embellish the wall with succulents or timekeepers and raise your home style.</p>
                      <h2 className='blog-page-title-2 pt-3'>3. Alterable Dimensions</h2>
                      <p className='blog-page-para'> This wooden construction is an incredible pick for the main room bogus roof plan. The white shade of the walls further features the sparkly wooden laminate. Another thought is to shift the wooden bars to an inclining point. Make a rare search for your dozing lair with this refined and negligible look.</p>
                      <h2 className='blog-page-title-2 pt-3'>4. Alluring Designs</h2>
                      <p className='blog-page-para'> A greater part of individuals try not to involve wooden bogus plan thoughts for the kitchen, nonetheless, trust us, it’s anything but an impractical notion by any means to add a rural touch to your kitchen! Ensure you utilize extraordinarily treated wood that is fire-and stickiness safe. Utilizing lights is smart to draw eyes upwards</p>
                      <p className='blog-page-para'> You name it, let’s paint them…</p>
                      <h2 className='blog-page-title-2 pt-3'>5. Newfangled Spaces</h2>
                      <p className='blog-page-para'> A gallery appears to be deficient without the newness of plants. For little galleries, the space isn’t generally enough for your planting leisure activity. Subsequently, hanging creepers and growers on the wooden beam above is an incredible pick for the exhibition’s misleading roof plan. On the off chance that you like to engage individuals, utilizing a basic wooden roof plan with shine lights makes for a tasteful party space.</p>
                      <img src={blog5a} alt="cookscape-wooden-ceiling-design" class="blog-img-size pt-3"/>
                      <h2 className='blog-page-title-2 pt-3'>6. Assorted Patterns</h2>
                      <p className='blog-page-para'>  Indeed, you need to feature you’re eating table and the enticing food on it, subsequent to having dinner with your family is a tangible encounter. Your feasting lobby wooden roof configuration needs to place your table at the center of attention.</p>
                      <p className='blog-page-para'>  You might consider a wooden framing that makes a more characterized space. Matching it with a warm metal blend is rich and everlastingly stylish. The glow and comfortable look of the wooden misleading roof configuration certainly add appeal to your dinner, wouldn’t you say?</p>
                      <h2 className='blog-page-title-2 pt-3'>7. Peak of Tranquility</h2>
                      <p className='blog-page-para'>  Luxury false ceiling design in wood complements the light-coloured walls and highlights the deity place. It’s simple, neat and elegant. Wood and glass light up the space, going with it an incredible decision for pooja room bogus roof plans.</p>
                      <img src={arbhaghyasri} alt="Architect-Bhaghyashri" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Bhaghyashri</h1>
                    </div>
                  </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog1e;
