// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Form } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/Notfound';
import Navbar from './components/Navbar';
import Interior from './pages/Interior';
import Kitchen from './pages/Kitchen';
import Renovation from './pages/Renovation';
import Happenings from './pages/Happenings';
import Contactus from './pages/Contactus';
import Pressrelease from './pages/Pressrelease';
import Gallery from './pages/Gallery';
import Groupwebsites from './pages/Groupwebsites';
import Customerspeaks from './pages/Customerspeaks';
import Blog from './pages/Blog';
import Blog2 from './pages/Blog2';
import Blog3 from './pages/Blog3';
import Blog4 from './pages/Blog4';
import Blog5 from './pages/Blog5';
import Blog6 from './pages/Blog6';
import Blog7 from './pages/Blog7';
import Blog8 from './pages/Blog8';
import Blog1a from './pages/Blog1a';
import Blog1b from './pages/Blog1b';
import Blog1c from './pages/Blog1c';
import Blog1d from './pages/Blog1d';
import Blog1e from './pages/Blog1e';
import Blog1f from './pages/Blog1f';
import Blog1g from './pages/Blog1g';
import Blog1h from './pages/Blog1h';
import Blog1i from './pages/Blog1i';
import Blog1j from './pages/Blog1j';
import Blog2a from './pages/Blog2a';
import Blog2b from './pages/Blog2b';
import Blog2c from './pages/Blog2c';
import Blog2d from './pages/Blog2d';
import Blog2e from './pages/Blog2e';
import Blog2f from './pages/Blog2f';
import Blog2g from './pages/Blog2g';
import Blog2h from './pages/Blog2h';
import Blog2i from './pages/Blog2i';
import Blog2j from './pages/Blog2j';
import Blog3a from './pages/Blog3a';
import Blog3b from './pages/Blog3b';
import Blog3c from './pages/Blog3c';
import Blog3d from './pages/Blog3d';
import Blog3e from './pages/Blog3e';
import Blog3f from './pages/Blog3f';
import Blog3g from './pages/Blog3g';
import Blog3h from './pages/Blog3h';
import Blog3i from './pages/Blog3i';
import Blog4a from './pages/Blog4a';
import Blog4b from './pages/Blog4b';
import Blog4c from './pages/Blog4c';
import Blog4d from './pages/Blog4d';
import Blog4e from './pages/Blog4e';
import Blog4f from './pages/Blog4f';
import Blog4g from './pages/Blog4g';
import Blog4h from './pages/Blog4h';
import Blog4i from './pages/Blog4i';
import Blog4j from './pages/Blog4j';
import Blog5a from './pages/Blog5a';
import Blog5b from './pages/Blog5b';
import Blog5c from './pages/Blog5c';
import Blog5d from './pages/Blog5d';
import Blog5e from './pages/Blog5e';
import Blog5f from './pages/Blog5f';
import Blog5g from './pages/Blog5g';
import Blog5i from './pages/Blog5i';
import Blog5h from './pages/Blog5h';
import Blog5j from './pages/Blog5j';
import Blog6a from './pages/Blog6a';
import Blog6b from './pages/Blog6b';
import Blog6c from './pages/Blog6c';
import Blog6d from './pages/Blog6d';
import Blog6e from './pages/Blog6e';
import Blog6f from './pages/Blog6f';
import Blog6h from './pages/Blog6h';
import Blog6g from './pages/Blog6g';
import Blog6i from './pages/Blog6i';
import Blog6j from './pages/Blog6j';
import Blog7a from './pages/Blog7a';
import Blog7b from './pages/Blog7b';
import Blog7c from './pages/Blog7c';
import Blog7d from './pages/Blog7d';
import Blog7e from './pages/Blog7e';
import Blog7f from './pages/Blog7f';
import Blog7g from './pages/Blog7g';
import Blog7h from './pages/Blog7h';
import Blog7i from './pages/Blog7i';
import Blog8a from './pages/Blog8a';
import Blog8b from './pages/Blog8b';
import Blog8c from './pages/Blog8c';
import Blog8e from './pages/Blog8e';
import Blog8f from './pages/Blog8f';
import Blog8g from './pages/Blog8g';
import Wardrobe from './pages/Wardrobe';
import Footer from './components/Footer';
import Blog3j from './pages/Blog3j';
import Blog8d from './pages/Blog8d';
import ScrollToTop from './components/ScrollToTop';
import Privacypolicy from './pages/Privacypolicy';
import Kidsfurniture from './pages/Kidsfurniture';
import Entertainmentunit from './pages/Entertainmentunit';
import Poojaunit from './pages/Poojaunit';
import Crockeryunit from './pages/Crockeryunit';
function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/interior" element={<Interior />} />
        <Route path="/kitchen" element={<Kitchen />} />
        <Route path="/wardrobe" element={<Wardrobe />} />
        <Route path="/kids-furniture" element={<Kidsfurniture />} />
        <Route path="/pooja-unit" element={<Poojaunit />} />
        <Route path="/tvunit" element={<Entertainmentunit />} />
        <Route path="/crockery" element={<Crockeryunit />} />
        <Route path="/renovation" element={<Renovation />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/understanding-the-theme-and-concept-in-interior-design" element={<Blog1a />} />
        <Route path="/clever-design-solutions-for-a-rain-water-friendly-balcony" element={<Blog1b />} />
        <Route path="/10-major-kitchen-storage-mistakes-to-avoid" element={<Blog1c />} />
        <Route path="/what-businesses-can-be-combined-with-interior-designing-business" element={<Blog1d />} />
        <Route path="/7-amazing-wooden-ceiling-designs-you-will-love" element={<Blog1e />} />
        <Route path="/how-to-prevent-dampness-from-the-wall" element={<Blog1f />} />
        <Route path="/smarter-interior-design-for-the-smart-homes" element={<Blog1g />} />
        <Route path="/12-reasons-to-love-chalk-paint" element={<Blog1h />} />
        <Route path="/indoor-decoration-ideas-with-money-plant" element={<Blog1i />} />
        <Route path="/how-can-persuasive-design-be-used-in-interior-design" element={<Blog1j />} />
        <Route path="/blog-2" element={<Blog2 />} />
        <Route path="/clever-storage-ideas-for-small-bedroom" element={<Blog2a />} />
        <Route path="/why-less-is-more-when-it-comes-to-home-interiors" element={<Blog2b />} />
        <Route path="/inventive-ideas-under-stairs-storage" element={<Blog2c />} />
        <Route path="/10-brilliant-ways-to-brighten-your-dark-kitchen-living-room" element={<Blog2d />} />
        <Route path="/what-is-the-best-material-for-an-interior-design" element={<Blog2e />} />
        <Route path="/is-using-an-interior-designer-expensive" element={<Blog2f />} />
        <Route path="/transform-your-home-with-the-right-lighting" element={<Blog2g />} />
        <Route path="/how-do-you-design-the-best-kids-room-interior" element={<Blog2h />} />
        <Route path="/ideas-to-design-a-multipurpose-living-room" element={<Blog2i />} />
        <Route path="/tamil-nadu-traditional-house-designs" element={<Blog2j />} />
        <Route path="/blog-3" element={<Blog3 />} />
        <Route path="/how-can-i-design-the-interior-of-my-home-with-a-low-budget" element={<Blog3a />} />
        <Route path="/what-is-the-theme-concept-in-interior-design" element={<Blog3b />} />
        <Route path="/easy-step-to-plan-your-kitchen-very-modular" element={<Blog3c />} />
        <Route path="/how-is-interior-wall-thickness-determined" element={<Blog3d />} />
        <Route path="/what-is-calypso-variant" element={<Blog3e />} />
        <Route path="/what-is-suede-variant" element={<Blog3f />} />
        <Route path="/what-is-amaryllis-variant" element={<Blog3g />} />
        <Route path="/what-is-veneer-variant" element={<Blog3h />} />
        <Route path="/what-is-iris-variant" element={<Blog3i />} />
        <Route path="/what-is-classic-variant" element={<Blog3j />} />
        <Route path="/blog-4" element={<Blog4 />} />
        <Route path="/what-is-traditional-variant-why-it-is-economic" element={<Blog4a />} />
        <Route path="/what-is-alumix-variant" element={<Blog4b />} />
        <Route path="/what-is-4x-tuff-gloss-variant" element={<Blog4c />} />
        <Route path="/what-is-premium-vanity" element={<Blog4d />} />
        <Route path="/what-is-vanity-variant" element={<Blog4e />} />
        <Route path="/why-to-choose-surabhi-variant" element={<Blog4f />} />
        <Route path="/what-is-capricoast-glossy-variant" element={<Blog4g />} />
        <Route path="/what-is-capricoast-matte-variant" element={<Blog4h />} />
        <Route path="/tips-to-choose-accessories-appliances-for-your-modular-kitchen" element={<Blog4i />} />
        <Route path="/how-to-decor-your-home-in-phases-with-time-intervals" element={<Blog4j />} />
        <Route path="/blog-5" element={<Blog5 />} />
        <Route path="/unique-design-ideas-for-small-kitchen-storage-space" element={<Blog5a />} />
        <Route path="/exclusive-tips-to-maximize-space-in-your-wardrobe" element={<Blog5b />} />
        <Route path="/what-services-does-cookscape-offer" element={<Blog5c />} />
        <Route path="/10-questions-you-must-ask-your-interior-designer" element={<Blog5d />} />
        <Route path="/transform-your-bathroom-into-home-spa" element={<Blog5e />} />
        <Route path="/ideas-to-brighten-up-a-dark-kitchen" element={<Blog5f />} />
        <Route path="/how-to-design-your-bedroom-ideal-for-the-peaceful-sleep-using-interior" element={<Blog5g />} />
        <Route path="/interior-design-ideas-for-latest-pooja-room-design" element={<Blog5h />} />
        <Route path="/lovely-wardrobe-design-ideas-for-your-kids-a-design-for-the-changing-world" element={<Blog5i />} />
        <Route path="/stay-updated-with-the-latest-design-trends" element={<Blog5j />} />
        <Route path="/blog-6" element={<Blog6 />} />
        <Route path="/top-5-reasons-to-choose-an-interior-design-for-home" element={<Blog6a />} />
        <Route path="/how-to-choose-the-best-interior-designer" element={<Blog6b />} />
        <Route path="/how-the-modular-kitchen-help-us-tips-to-maintenance" element={<Blog6c />} />
        <Route path="/10-epic-decorating-ideas-to-your-kids-room" element={<Blog6d />} />
        <Route path="/why-does-an-interior-designer-matter" element={<Blog6e />} />
        <Route path="/tips-on-choosing-interior-contractor-for-residence" element={<Blog6f />} />
        <Route path="/how-are-we-going-to-resume-our-office-after-lockdown" element={<Blog6g />} />
        <Route path="/what-is-magical-wonder-will-happen-after-completing-your-home-interior-design" element={<Blog6h />} />
        <Route path="/why-an-interior-designer-matter" element={<Blog6i />} />
        <Route path="/factors-to-consider-while-choosing-an-interior-design-company" element={<Blog6j />} />
        <Route path="/blog-7" element={<Blog7 />} />
        <Route path="/how-to-renovate-your-kitchen-within-your-budget" element={<Blog7a />} />
        <Route path="/enhance-home-with-efficient-interiors" element={<Blog7b />} />
        <Route path="/technology-and-the-future-of-interior-design-industry" element={<Blog7c />} />
        <Route path="/step-by-step-guide-to-perfect-your-interior-design" element={<Blog7d />} />
        <Route path="/ideas-to-incorporate-a-veg-fruit-garden-in-your-home-interior-design" element={<Blog7e />} />
        <Route path="/crazy-and-innovative-ideas-for-kitchen-storage-organization" element={<Blog7f />} />
        <Route path="/ideas-to-separate-living-and-dining" element={<Blog7g />} />
        <Route path="/essential-interior-design-tips-for-rental-property" element={<Blog7h />} />
        <Route path="/how-to-choose-comfortable-furniture-for-family-room" element={<Blog7i />} />
        <Route path="/blog-8" element={<Blog8 />} />
        <Route path="/importance-of-interior-design-a-small-overview" element={<Blog8a />} />
        <Route path="/how-to-choose-the-right-interior-design-company" element={<Blog8b />} />
        <Route path="/interior-design-ideas-for-your-dream-home" element={<Blog8c />} />
        <Route path="/tips-for-choosing-the-best-interior-designer-for-your-dream-home" element={<Blog8d />} />
        <Route path="/why-experts-are-crucial-to-design-and-build-dream-homes" element={<Blog8e />} />
        <Route path="/why-hire-an-interior-designer-instead-of-a-carpenter" element={<Blog8f />} />
        <Route path="/why-chennai" element={<Blog8g />} />
        <Route path="/happenings" element={<Happenings />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/press-release" element={<Pressrelease />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/group-websites" element={<Groupwebsites />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/customer-speaks" element={<Customerspeaks />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer/>
    </Router>
  );
}
export default App;
