import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog59 from '../images/blogs/blog-images/cookscape-blog-59 .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';

const Blog6i = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                  <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>WHY AN INTERIOR DESIGNER MATTER?</h1>
                      <img src={blog59} alt="cookscape-interior-design" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Every individual has the capacity to decorate their personal space, but when it comes to creating that personal space conducive of the area for togetherness, it’s very much suggested to consult an Interior designer.</p>
                      <p className='blog-page-para'>No matter what you put in your place, interior design lends a great big hand in making sure that your space is both aesthetically pleasing and functional.</p>
                      <p className='blog-page-para'> Interior design solves problems and decides on what’s the best use of space. While taking care of safety and making use of all the available possibilities for a given space.</p>
                      <p className='blog-page-para'> Interior design makes your space functional, comfortable and pleasure to live, a service of good ideas for interior can be given by a professional like an interior designer or architect.</p>
                      <p className='blog-page-para'> While designing a space they not only consider their clients present requirement but also consider their future requirement so that they can use the space and be comfortable in future also.</p>
                      <p className='blog-page-para'> An interior designer will not only consider the aesthetics but also long durability and also consider if there could be any side effects of the materials used to the environment of the house.</p>
                      <p className='blog-page-para'>Whether it will be a commercial space or a residential space an interior designer will give following mentioned services.</p>
                      <p className='blog-page-para'>1. Space Planning: To use the most of space available</p>
                      <p className='blog-page-para'>2. Project management: Not only giving the designs but giving a finished product.</p>
                      <p className='blog-page-para'>3. Color consulting: Helping clients in creating themes.</p>
                      <p className='blog-page-para'>4. Architectural detailing.</p>
                      <h2 className='blog-page-title-2 pt-3'>5. Lighting placement.</h2>
                      <p className='blog-page-para'>Interior designers (Professionals like Architects and interior designers) specifically matter because they not only help in designing but also help us get our ideas into a practical project within our budget.</p>
                      <p className='blog-page-para'>Finished product of an interior done with a help of such professionals will be aesthetically excellent and confortable to use and pleasing to spend our time in that space.</p>
                      <img src={arsaranya} alt="Architect-Saranya" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'>Architect Saranya</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6i;
