import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog58 from '../images/blogs/blog-images/cookscape-blog-58 .webp';
import armuthulakshmi from '../images/blogs/architects/Cookscape-Architect-Muthulakshmi .webp';
const Blog6h = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>WHAT IS MAGICAL WONDER WILL HAPPEN AFTER COMPLETING YOUR HOME INTERIOR DESIGN?</h1>
                      <img src={blog58} alt="cookscape-interior-design-ideas" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Every person is awestruck when something Magical happens and our Interior design makes you feel that magic.</p>
                      <p className='blog-page-para'>The magic that interior design could bring in your life is real. However if you know the tips and tricks to create beautiful space you would realise anyone can create magic.</p>
                      <h2 className='blog-page-title-2 pt-3'>1. MAGIC WITH CEILING</h2>
                      <p className='blog-page-para'> By using “glow in the dark” radium stickers and wallpapers your bedroom’s ordinary ceiling can be converted into a space observatory. Magic is thus created.</p>
                      <p className='blog-page-para'> False ceilings are not only decorative but also used as air regulators, sag resistors, thermal insulators, etc. It’s available in various types of finishes from wood to glass and fiber to plastic. It also makes the space look compat anc cozy.</p>
                      <h2 className='blog-page-title-2 pt-3'>2. MAGIC WITH LIGHTS</h2>
                      <p className='blog-page-para'> Light plays a major role in changing the ambiance of the room. With different intensity and different colors of lights, you will get a dynamic environment depends on your mood.</p>
                      <p className='blog-page-para'> Sunlight is the natural source of light that can be controlled and regulated using more suitable window coverings.</p>
                      <p className='blog-page-para'>Chandelier looks more luxurious and also provides both uplighting & down lighting.</p>
                      <p className='blog-page-para'>The table lamp produces light that highlights the ceiling at each and every corner of the room. Recessed lights in the ceiling and floor will highlight the textures of the wall.</p>
                      <p className='blog-page-para'>The Pendant lighting in workspaces, under cabinet lighting in kitchen, landscape lighting in garden highlight intricate details of your dream home.</p>
                      <h2 className='blog-page-title-2 pt-3'>3. MAGIC WITH CURTAINS AND CARPETS</h2>
                      <p className='blog-page-para'>As we mentioned earlier, window coverings like Venetian, louver or roman blinds regulate and control the natural sunlight. It is also used for ornamental and aesthetic purposes. The material and color of the curtains depicts the different ages and different style of people around the world.</p>
                      <p className='blog-page-para'>Surface designs using rugs and carpet not only make you feel warm and comfortable but also act as a sound insulator.</p>
                      <h2 className='blog-page-title-2 pt-3'>4. MAGIC WITH MIRRORS</h2>
                      <p className='blog-page-para'>Mirrors create an illusion that looks room more spacious and it also does magic with lights and reflections. Big mirrors on the wall occupy the empty space with a more impressive look.</p>
                      <p className='blog-page-para'>Designer mirrors with frames are very stylish and replace the need for wallpapers in the living room. It reflects all the detail in the room. Mirror built-in closets and dressers will fix the issue of lack of space when you have to design the interior in less space.</p>
                      <p className='blog-page-para'>Mirror built-in closets and dressers will fix the issue of lack of space when you wanted to do the interior in minimal space.</p>
                      <p className='blog-page-para'>Geometric shapes mirrors in a room look more artistic that will replace any artwork or scenery on the wall.</p>
                      <h2 className='blog-page-title-2 pt-3'>5. MAGIC WITH WOODWORKS</h2>
                      <p className='blog-page-para'>The quality, durability, and appearance of woodwork cannot be replaced by any other material when it comes to interior design. But you can get the feel of all materials in wood by using the desired laminates and finishes.</p>
                      <p className='blog-page-para'>One can easily customize any storage units as per their requirements. It can also act as an insulator, noise absorber, etc., if required its surface can be treated, carved, cut into desired shapes and designs.</p>
                      <p className='blog-page-para'>The best thing now is to go with woodwork that you can match with your house elements like doors, windows, cot, Tables, stairs, etc. The laminates of the woodwork can also closely resemble natural wood like oak wood, cedarwood, Teak wood and many more.</p>
                      <p className='blog-page-para'>When you finally know the tricks of the interior, you can live in a place where you dreamt of, with the magic around you, which will awaken your senses.</p>
                      <img src={armuthulakshmi} alt="Architect-Muthulakshmi" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'>Architect Muthulakshmi</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6h;
