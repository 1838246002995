import React from 'react';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import orbix from '../images/services/poojaunit/Orbix .webp';
import omr from '../images/services/poojaunit/omrhome .webp';
import walltowall from '../images/services/poojaunit/walltowall .webp';
const Poojaunit = () => {
  return (
    <>
        <div className="container-fluid services-breadcrumb">
            <h3 className="cookscape-services-title">Cookscape Pooja Unit Ideas</h3>
        </div>
        <div className='container-fluid aboutus'>
      <div className='container'>
        <div className='row websites'>
          <div className='col-md-6'>
              <div className='group'>
                  <img src={orbix} alt="orbix-designs" class="img-fluid"/>
                      <div class="overlay5">
                          <a href="#" class="icon" title="Orbix Website">
                         
                          <p class="group-title">www.orbixdesigns.com</p></a>
                      </div>
                </div>
          </div>

          <div className='col-md-6'>
          <p class="website-title pt-5">ORBIX DESIGNS</p>
                <h1 class="website-title-main">Interior Design</h1>
                <p class="website-para">Orbix Designs is a leading one-stop solution Interior Design Company in Chennai Including all of South India. Our experience spans over 3 decades, and thousands of projects & Counting. We serve only the best of the best in Interior Design to all our clients at an affordable rate.</p>
                <button class="aboutus-button-2">View more</button>
          </div>
        </div>

        <div className='row websites'>
          <div className='col-md-6'>
          <p class="website-title pt-5">OMR HOME INTERIORS</p>
                <h1 class="website-title-main">Interior Design</h1>
                <p class="website-para">OMR Homes is a trusted name in interior design, offering comprehensive solutions across Chennai. With a rich history of delivering excellence and a portfolio of thousands of completed projects, we pride creating exceptional spaces. We strive to incorporate high-quality designs with affordable prices for all our customers, thereby delivering the best service and value. Our services cover a single-room transformation to complete home redesigning with expert precision and finesse.</p>
                <button class="aboutus-button-2">View more</button>
          </div>

          <div className='col-md-6'>
          <div className='group'>
                  <img src={omr} alt="omr-homes" class="img-fluid"/>
                      <div class="overlay5">
                          <a href="#" class="icon" title="OMR Home Interiors">
                          
                          <p class="group-title">www.omrhomes.com</p></a>
                      </div>
                </div>
          </div>
        </div>

        <div className='row websites'>
          <div className='col-md-6'>
          <div className='group'>
                  <img src={walltowall} alt="walltowall-home-decor" class="img-fluid"/>
                      <div class="overlay5">
                          <a href="#" class="icon" title="WallToWallHomeDecor">
                          
                          <p class="group-title">www.walltowallhomedecor.in</p></a>
                      </div>
                </div>
          </div>

          <div className='col-md-6'>
          <p class="website-title pt-5">WALL TO WALL HOME DECOR</p>
                <h1 class="website-title-main">Home Decor</h1>
                <p class="website-para">Wall to Wall is one of the most reliable suppliers of custom interior solutions within categories such as curtains, wall panels, home decor, wallpapers, and many more. Our products are high in quality and can add great beauty to your place, along with performing properly within that space. We have the most closely knit relationship with customers that provides personalized solutions that will suit every style and budget. Whether it's just a single room or an overhaul of the entire space, Wall to Wall will bring your ideas to life with care and creativity.</p>
                <button class="aboutus-button-2">View more</button>
          </div>
        </div>
      </div>
    </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Poojaunit;
