import React from 'react';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import press1 from '../images/press/Press-Release-1 .webp';
import press2 from '../images/press/Press-Release-5 .webp';
import press3 from '../images/press/Press-Release-4 .webp';
import press4 from '../images/press/Press-Release-6 .webp';
import press5 from '../images/press/Press-Release-2 .webp';
import press6 from '../images/press/Press-Release-3 .webp';
const Pressrelease = () => {
  return (
    <>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Press Release</h3>
    </div>
    <div className="container-fluid aboutus">
        <div class="container pt-5">
            <div class="row justify-content-center">
                <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={press1} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={press2} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={press3} alt="" class="img-fluid"/>
                        </a>
                  </div>
                  <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={press4} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={press5} alt="" class="img-fluid"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                            <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={press6} alt="" class="img-fluid"/>
                        </a>
                    </div>
                </div>
              </div>
            </div>
          <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
              <div class="modal-dialog modal-xl modal-dialog-centered">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title" id="exampleLightboxLabel">Cookscape Gallery</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                  <div class="modal-body">
                    <div id="lightboxExampleCarousel" class="carousel slide">
                        <div class="carousel-inner ratio ratio-16x9 bg-dark">
                            <div class="carousel-item text-center active">
                                <img  src={press1} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                                 <img  src={press2} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                                  <img  src={press3} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                                  <img  src={press4} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                                  <img  src={press5} alt="" class="img-fluid mh-100"/>
                            </div>
                            <div class="carousel-item text-center">
                                  <img  src={press6} alt="" class="img-fluid mh-100"/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Pressrelease;
