import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog75 from '../images/blogs/blog-images/cookscape-blog-75 .webp';
import arapsana from '../images/blogs/architects/Cookscape--Architect-Apsana .webp';
const Blog8e = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>WHY EXPERTS ARE CRUCIAL TO DESIGN AND BUILD “DREAM HOMES”?</h1>
                        <img src={blog75} alt="cookscape-build-design" class="img-fluid pt-3"/>
                        <p className='blog-page-para'>Owning a dream home is a familiar desire for many. However, due to lack of resources only a handful of people can afford to make this their reality. Hence, before spending a fortune on building a house it is imperative to make a wise investment. Therefore hiring building professionals will not only assure a cozy and elegant home design but also will enhance the quality of construction.</p>
                        <h2 className='blog-page-title-2 pt-3'>Role of an Architect</h2>
                        <p className='blog-page-para'>An Architect’s job is to design a house prior to construction. Construction of a house is quiet a challenging endeavor and Architects can ease this process by taking charge of the project from the design to the execution phase. The spaces planned by architects are more efficient, environmentally sustainable and cost effective. Architects also come in handy for renovation projects as well as Façade Redesign projects. They spend time with their clients to understand their taste, cultural background and budget after which they start the design process and using their technical and creative knowledge they come up with the most suitable design. An architect can provide all the drawings which could be used on-site by engineers and contractors to build the structure.</p>
                        <h2 className='blog-page-title-2 pt-3'>Role of a Civil Engineer</h2>
                        <p className='blog-page-para'>Civil Engineers on the other hand help build the structure designed. They review drawings, study the structure of the house and make sure that the building meets the building codes requirement. They also look into the foundational strength of a building and the materials to be used for construction. They help in making a sturdy and durable dwelling.</p>
                        <h2 className='blog-page-title-2 pt-3'>Role of an Interior Designer</h2>
                        <p className='blog-page-para'>Once the construction is completed then it is the Interior Designer’s job to make sure that the interior spaces are utilized well and each room has its own unique character. They could design a home interior to give a modern, elegant, eclectic, classic and various other kinds of look. They also provide advice and ideas on the different kinds of accessories or decorative furniture that could be bought. Also the best materials to be used for home furniture and many interior designers also design customized furniture to match the unique character of a house.</p>
                        <p className='blog-page-para'>These professionals are very important to not only make a good structure but also to help people to turn their dreams into reality.</p>
                        <img src={arapsana} alt="ArchitectApsana" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'> Architect Apsana</h1>
                    </div>
                </div> 
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
</>
              );
            };
export default Blog8e;
