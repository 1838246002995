import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog19 from '../images/blogs/blog-images/cookscape-blog-19 .webp';
import blog19a from '../images/blogs/blog-images/cookscape-blog-19a .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
const Blog2i = () => {
        return (
                <>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>Ideas to Design a Multipurpose Living Room!</h1>
                      <img src={blog19} alt="cookscape-living-room" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Multipurpose living room: Some people might ask a question why a living room has to be a multipurpose room, but many people know that now a day the apartments have smaller living spaces which need to be customized to fulfill needs of the owner.</p>
                      <p className='blog-page-para'>For Indian families where we are big family with more than 5 people which makes it almost 3 generation , use of every small space available is very ,much required , in that time if we have option of using a same room for different purposes , it will be very helpful.</p>
                      <p className='blog-page-para'>Most of the people wants their living space to act as an inviting space, at the same time should be a dining area, at the same time have lot of storage space for either showcase or books, and </p>
                      <p className='blog-page-para'>sometimes they even want their living space to act as a bedroom or sleeping space. </p>
                      <p className='blog-page-para'>While designing we should not only focus on the solid and fixed storage which will help with showcase and books storage spaces , but also should be considerate about the loose furniture which is going to bring the theme to the design. </p>
                      <p className='blog-page-para'>A compact TV unit with storage spaces, for display units can be an option at the same time option with foldable storage which can act as a bed or a sofa space when opened and storage space when fixed on wall. Interior designs have enormous options available for each issue faced by us, but according to options price and life durability affects, in the end definitely you cannot get gold when you want to pay for a silver. When we are ready to pay we have many things to explore in interior. </p>
                      <p className='blog-page-para'>In loose furniture’s itself now multipurpose customized furniture’s are available. </p>
                      <p className='blog-page-para'>Few loose furniture’s that will help in making a living apace compatible for multipurpose use. </p>
                      <p className='blog-page-para'>1. While buying a sofa we can choose a sectional sofa which can be extended, to few more seaters or can be extended into a bed during night time very much useful for Indian families. </p>
                      <p className='blog-page-para'>2. Side tables of the sofa can be choosed like something that can even be used as a seater when there is a function or party been hosted at house. </p>
                      <p className='blog-page-para'>3. Coffee table which is very sleek and simple but have some storage space can definitely help, it can turn into a laptop desk during some unplanned official discussion or meeting at home. </p>
                      <p className='blog-page-para'>4. Expandable dining table , according to required it can withstand 4 -8 peoples at same time can be used for some official discussions during more no of members , at the same time can be helpful for Indian parents to help their small children’s with their study while doing their own works side by side. </p>
                      <img src={blog19a} alt="cookscape-living-room" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>Depending upon the requirement of the client, the interior designer can suggest the theme, help in colors that will not only suit for regular staying but also supports party plans and functional environment.</p>
                      <img src={arsaranya} alt="Architect-Saranya" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Saranya</h1>
                    </div>
                  </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog2i;
