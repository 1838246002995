// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/home/cookscape-logo .webp';
const Footer = () => {
  return (
    <div className='container-fluid footer'>
    <div className='row'>
      <div className='col-md-3'>
      <Link to="/">
      <img src={logo} alt="" class="logo"/>
      </Link>
      <p class="aboutus-para">
      We are India's 2nd largest home interior designer store, located in Chennai, offering expert services to transform your living space. Whether you’re redesigning your home or creating a brand-new look, our team is here to craft unique and functional designs tailored just for you.</p>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">Quick Links</h3>
          <ul>
            <li class="footer-link"><Link to="/"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Home</a></Link></li>
           <li class="footer-link"> <Link to="/interior"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Interior</a></Link></li>
            <li class="footer-link"><Link to="/kitchen"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Modular Kitchen</a></Link></li>
            <li class="footer-link"><Link to="/renovation"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Renovation</a></Link></li>
            <li class="footer-link"><Link to="/gallery"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">View Our Sites</a></Link></li>
          </ul>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">Services</h3>
          <ul>
            <li class="footer-link"><Link to="/wardrobe"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Wardrobe Design</a></Link></li>
            <li class="footer-link"><Link to="/kids-furniture"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Kids Furniture</a></Link></li>
            <li class="footer-link"><Link to="/blog"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Blogs</a></Link></li>
            <li class="footer-link"><Link to="/contactus"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Outlet</a></Link></li>
            <li class="footer-link"><Link to="/privacy-policy"><i class="fa fa-arrow-right" aria-hidden="true"></i><a href="">Privacy Policy</a></Link></li>
          </ul>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">Contact us</h3>
          <ul>
            <li class="footer-link"><i class="fa fa-phone" aria-hidden="true"></i>
              <a href="">+91-9150005679</a></li>
            <li class="footer-link"><i class="fa fa-envelope" aria-hidden="true"></i><a href="">info@cookscape.com</a></li>
            <li class="footer-link"><i class="fa fa-map-marker" aria-hidden="true"></i>
              <a href="">557/338, Anna Salai, Fanepet, Nandanam, Chennai, Tamil Nadu 600035.</a></li>
          </ul>
          <div class="social">
          <Link to="https://www.facebook.com/cookscape/" target="_blank" rel="noopener noreferrer"> <i class="fa fa-facebook" aria-hidden="true" > </i></Link>
          <Link to="https://www.instagram.com/cookscape_interior/" target="_blank" rel="noopener noreferrer">  <i class="fa fa-instagram" aria-hidden="true"></i></Link>
          <Link to="https://www.youtube.com/@CookscapeInterior/videos" target="_blank" rel="noopener noreferrer"> <i class="fa fa-youtube-play" aria-hidden="true"></i></Link>
          <Link to="https://x.com/Cookscape1" target="_blank" rel="noopener noreferrer"> <i class="fa fa-linkedin-square" aria-hidden="true"></i></Link>
          </div>
      </div>
      </div>
      <div class="copyright">
      <p class="copyright-para">By clicking this page, you agree to our Terms & Conditions and Privacy Policy | © Copyright 2024 All Rights Reserved © Cookscape Private Limited.</p>
    </div>
    </div>
  );
};
export default Footer;
