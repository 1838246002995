// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog72 from '../images/blogs/blog-images/cookscape-blog-72 .webp';
import arbagya from '../images/blogs/architects/Cookscape-Architect-Bagya-Lakshmi .webp';
const Blog8b = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                  <div className='container p-5'>
                      <h1 className='blog-page-title pt-3'>HOW TO CHOOSE THE RIGHT INTERIOR DESIGN COMPANY?</h1>
                      <img src={blog72} alt="cookscape-right-interior-design" class="img-fluid pt-3"/>
                      <p className='blog-page-para'>“As How the Quality and Function of the building is important? As such the aesthetic of the building is also important”, this suits both the exterior and interior of the building. Be it residential or commercial interior plays a vital role in attraction and accessibility. To choose the Right Interior design company we need to consider the list of points that the design company has to satisfy, they may follow:</p>
                      <h2 className='blog-page-title-2 pt-3'>1. Ease to Access:</h2>
                      <p className='blog-page-para'>The Company should have proper access to know their profiles, location, and contact number for the ones who have no idea about the company and no idea about how to start their process.</p>
                      <h2 className='blog-page-title-2 pt-3'>2. Services they provide:</h2>
                      <p className='blog-page-para'>One must know what are the services a company provide, that too when coming to Home Interiors there are ‘N’ number of things that too be considered in which the major quantity will cover the Wood Work, as it requires wide range of research to know about its quality, durability, finishes, etc… apart from that every other work like false ceiling, Wallpaper, Partition, etc are the value additions which may differ as our requirements.</p>
                      <h2 className='blog-page-title-2 pt-3'>3. Profile and Portfolio:</h2>
                      <p className='blog-page-para'>Must ask for their profile and the portfolio of completed projects to get to know about their types of finishes and generous work.</p>
                      <h2 className='blog-page-title-2 pt-3'>4. Cost and Customizations:</h2>
                      <p className='blog-page-para'>The product that we get should be the worth of the money. One should know what the packages available and how flexible till what norms of customization.</p>
                      <h2 className='blog-page-title-2 pt-3'>5. Oodles Design Ideas & Solution:</h2>
                      <p className='blog-page-para'>According to our requirements the company/designers should come up with plenty of design ideas that will be both feasible and aesthetic.</p>
                      <h2 className='blog-page-title-2 pt-3'>6. Dispute free Service:</h2>
                      <p className='blog-page-para'>From day one till the Installation and completion of the project, one should ensure that there are no discrepancies faced at any stage in the whole process.</p>
                      <img src={arbagya} alt="Architect-Bagya-Lakshmi" class="blog-page-author-img pt-3"/>
                      <h1 className='blog-title pt-3'> Architect Bagya Lakshmi</h1>
                    </div>
                  </div> 
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
</>
              );
            };
export default Blog8b;
