import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog61 from '../images/blogs/blog-images/cookscape-blog-61 .webp';
import blog62 from '../images/blogs/blog-images/cookscape-blog-62 .webp';
import blog63 from '../images/blogs/blog-images/cookscape-blog-63 .webp';
import blog64 from '../images/blogs/blog-images/cookscape-blog-64 .webp';
import blog65 from '../images/blogs/blog-images/cookscape-blog-65 .webp';
import blog66 from '../images/blogs/blog-images/cookscape-blog-66 .webp';
import blog67 from '../images/blogs/blog-images/cookscape-blog-67 .webp';
import blog68 from '../images/blogs/blog-images/cookscape-blog-68 .webp';
import blog69 from '../images/blogs/blog-images/cookscape-blog-69 .webp';


import arviswa from '../images/blogs/architects/Cookscape-Architect-Viswanath .webp';
import argomathi from '../images/blogs/architects/Cookscape-Architect-Gomathi-Varshini .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
import ardarshna from '../images/blogs/architects/Cookscape-Architect-Darshna .webp';
import arvasuki from '../images/blogs/architects/Cookscape-Architect-Vasuki-Kathirvel .webp';
import arneshma from '../images/blogs/architects/Cookscape-Architect-Neshma .webp';
import arbagya from '../images/blogs/architects/Cookscape-Architect-Bagya-Lakshmi .webp';
import arapsana from '../images/blogs/architects/Cookscape--Architect-Apsana .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';


function Blog7() {
  return (
    <>
    <Helmet>
      <title>Blog - Cookscape</title>
    </Helmet>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Blog</h3>
    </div>
    <div className="container-fluid aboutus">
        <div className="container">
        <div className="blog-row p-3">
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog61} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'>HOW TO RENOVATE YOUR KITCHEN WITHIN YOUR BUDGET?</h1>
                <p className='blog-para'> It is a process of repairing or changing something to a new form or giving a new shape and design. We generally do renovation for better aesthetics and convenience. Also kitchen renovation will give a boost to house resale value.... </p>
                <Link to="/how-to-renovate-your-kitchen-within-your-budget" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arviswa} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Viswanath</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog62} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> ENHANCE HOME WITH EFFICIENT INTERIORS!</h1>
                <p className='blog-para'> Home is the space where we live, school, work and dine. The smallest changes can save the spaces and make it refreshing, reviving and relaxing. Upgrade and reclaim your home from a crunched cluttered space to a sweet spot to unwind your long day..... </p>
                <Link to="/enhance-home-with-efficient-interiors" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={argomathi} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect A.Gomathi Varshini</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog63} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> STEP BY STEP GUIDE TO PERFECT YOUR INTERIOR DESIGN!</h1>
                <p className='blog-para'> It is challenging for a layman to be able to express their design ideas and transform them into a tangible reality. Hence, here is a simplified roadmap that could be helpful not only for laymen but also for amateur professionals in the field of interior design to create a methodical way of organizing home interior spaces..... </p>
                <Link to="/step-by-step-guide-to-perfect-your-interior-design" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={ardarshna} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Darshna R</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog64} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> IDEAS TO INCORPORATE A VEG & FRUIT GARDEN IN YOUR HOME INTERIOR DESIGN</h1>
                <p className='blog-para'> GARDEN is the space for display and cultivation. The main purpose of cultivating the fruits and vegetables in our home is to have Organic foods, as well; It is fun to have these activities in our indoor.....  </p>
                <Link to="/ideas-to-incorporate-a-veg-fruit-garden-in-your-home-interior-design"><button class="aboutus-button-2" target="_blank" rel="noopener noreferrer">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arvasuki} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'>Architect Vasuki Kathirvel</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog65} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> CRAZY AND INNOVATIVE IDEAS FOR KITCHEN STORAGE ORGANIZATION</h1>
                <p className='blog-para'> There are a lot of ideas available in the market for the pot and pan lid holder but the main idea is to keep one of the most fought pan lids to keep organized since most of the time these are not required for cooking... </p>
                <Link to="/crazy-and-innovative-ideas-for-kitchen-storage-organization" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arneshma} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Neshma</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog66} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'>IDEAS TO SEPARATE LIVING AND DINING</h1>
                <p className='blog-para'> Living and dining are two spaces mostly combined together but serve different functions. Separating them both without an enclosed barrier has the challenge to who designs it. Here comes the Home Interior architect plays a vital role with immense design ideas....</p>
                <Link to="/ideas-to-separate-living-and-dining" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arbagya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Bagya Lakshmi  </h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog67} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> ESSENTIAL INTERIOR DESIGN TIPS FOR RENTAL PROPERTY</h1>
                <p className='blog-para'> Most people do the interior for the house where they are going to live, but it is a generosity that a human should have for another human, where when we are allowing another person to stay in our house under a rental agreement we create a space for....
                </p>
                <Link to="/essential-interior-design-tips-for-rental-property" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsaranya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Saraniya</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog68} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> HOW TO CHOOSE COMFORTABLE FURNITURE FOR “FAMILY ROOM”</h1>
                <p className='blog-para'>The Larger Sofa occupies a wider space and don’t feel to be alone and also in guest visits they occupy a huge while gathering. We can place additional chairs also and they also provide pleasing visual contact.....</p>
                <Link to="/how-to-choose-comfortable-furniture-for-family-room" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arapsana} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Apsana</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog69} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> TECHNOLOGY AND THE FUTURE OF INTERIOR DESIGN INDUSTRY</h1>
                <p className='blog-para'> Technology is playing a huge role in updating homes. New residential solutions known as “Smarthomes” are becoming a very common phenomena especially in metro cities around the world and in India. Today the best interior design companies in Chennai are…....</p>
                <Link to="/technology-and-the-future-of-interior-design-industry" target="_blank" rel="noopener noreferrer"><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={aranu} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'>Architect Anulekha</h1>
                </div>
            </div>
            </div>
        </div>
        <div className='container'>
    <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
        <div class="pagination  align-items-center">
  <Link to="/blog" target="_blank" rel="noopener noreferrer"> <a>&laquo;</a></Link>
  <Link to="/blog" target="_blank" rel="noopener noreferrer">  <a>1</a></Link>
  <Link to="/blog-2" target="_blank" rel="noopener noreferrer"> <a>2</a></Link>
  <Link to="/blog-3" target="_blank" rel="noopener noreferrer"> <a>3</a></Link>
  <Link to="/blog-4" target="_blank" rel="noopener noreferrer"> <a>4</a></Link>
  <Link to="/blog-5" target="_blank" rel="noopener noreferrer"> <a>5</a></Link>
  <Link to="/blog-6" target="_blank" rel="noopener noreferrer"> <a>6</a></Link>
  <Link to="/blog-7" target="_blank" rel="noopener noreferrer"> <a class="active">7</a></Link>
  <Link to="/blog-8" target="_blank" rel="noopener noreferrer"> <a>8</a></Link>
  <Link to="/blog-8" target="_blank" rel="noopener noreferrer"><a>&raquo;</a></Link>
    </div>
        </div>
        <div className='col-md-2'></div>
    </div>


</div>
    </div>

  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog7;