import React from 'react';
import { Helmet } from 'react-helmet';
import kitchen3 from '../images/gallery/kitchen/cookscape-modular-kitchen-3.webp';
import blog52 from '../images/blogs/blog-images/cookscape-blog-52 .webp';
import blog52a from '../images/blogs/blog-images/cookscape-blog-52a .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
const Blog6b = () => {
        return (
                <>
                <Helmet>
                  <title>Blog - Cookscape</title>
                </Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid aboutus'>
                    <div className='container p-5'>
                          <h1 className='blog-page-title pt-3'>How to choose the best interior designer?</h1>
                          <img src={blog52} alt="cookscape-interior-designer-ideas" class="img-fluid pt-3"/>
                          <p className='blog-page-para'>IFor a Common man, the biggest dilemma is how to hire an outstanding interior designer who not only will understand his requirements but will also give feasible solutions for the same.</p>
                          <p className='blog-page-para'>There are few basic things one should keep in mind when he / she is choosing an interior designer, this will definitely make the process of design easy and consequently the end result will be satisfactory. </p>
                          <h2 className='blog-page-title-2 pt-3'>Steps one can follow: </h2>
                          <h2 className='blog-page-title-2 pt-3'>1. Finding out the style of design: </h2>
                          <p className='blog-page-para'> One should go through websites to do their basic homework in knowing about various options in  design styles  and  choose  the</p>
                          <p className='blog-page-para'>kind of style they like so that they can compare their aspects and designers’ aspects, which is more important because each and every designer has their own and signature style, which can and cannot match your needs. </p>
                          <img src={blog52a} alt="cookscape-interior-designer-ideas" class="img-fluid pt-3"/>
                          <h2 className='blog-page-title-2 pt-3'>2. They should view portfolios: </h2>
                          <p className='blog-page-para'>When one comes to know what their style is, one can easily identify designers that match their style. One can check through the portfolio of individual designers or firms to see their past works, so that they can get an idea on what they can expect in the end as a final product.</p>
                          <h2 className='blog-page-title-2 pt-3'>3. Set a budget:  </h2>
                          <p className='blog-page-para'>Once we finalize on style we need we should have a specific range of budget in mind with a basic idea on what all is required, Some designer charge a fix rate for their service, some have hourly based charges or design option based charges, so keeping a budget in mind will even help in narrowing down the options.</p>
                          <h2 className='blog-page-title-2 pt-3'>4. Meet the designer or the firm at their studio:</h2>
                          <p className='blog-page-para'>Once the options are narrowed down and organised to few suitable choices it is better to meet them with all in person. This initial meeting is normally not charged, but it’s better to confirm this upfront.</p>
                          <h2 className='blog-page-title-2 pt-3'>5. Ask lot of questions:</h2>
                          <p className='blog-page-para'>When we meet the designer we should ask a lot of questions related to design, timeline, clientele; people who can promote their work, details about the services they provide, most important thing to do during this discussion is to note down all the important things discussed.</p>
                          <h2 className='blog-page-title-2 pt-3'>6. Have an open Mind:</h2>
                          <p className='blog-page-para'>Being very much restricted about what we want sometimes doesn’t work, being a professional designer might have a better solution for the space so we should be comfortable in accepting those options also.</p>
                          <h2 className='blog-page-title-2 pt-3'>7. Compare notes:</h2>
                          <p className='blog-page-para'>Depending upon your discussions and quote and options you have received, comparing the things between the designers or firm will give a clear idea on whom you should go ahead with. Important point is not always going for the cheaper one is the best decision.</p>
                          <h2 className='blog-page-title-2 pt-3'>8. Sign a contract:</h2>
                          <p className='blog-page-para'>Once a decision is taken, one should inform the designer about the same so that they can get the document of contract ready for you to sign at the time of payment, make sure that you read all their terms and condition, timeline and etc before signing.</p>
                          <p className='blog-page-para'>Once the detail process starts make sure you give time to the designer as well as you have time to spend with the designer so that you can get most out of his within your budget. Plan things properly, keeping your and their schedule in mind, and site availability for completing the installation work.</p>
                          <img src={arsaranya} alt="Architect-Saranya" class="blog-page-author-img pt-3"/>
                          <h1 className='blog-title pt-3'>Architect Saranya</h1>
                        </div>
                      </div>
  
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <img src={kitchen3} alt="cookscape-amaryllis" class="img-fluid rounded pb-1"/>
                    <form id="contactForm" method="post" action="http://exotickitchen.co.in/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog6b;
